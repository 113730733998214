import classnames from 'classnames';
import Breadcrumb from 'components/Breadcrumb';

type Props = {
  category: string;
  homeUrl: string;
  title: string;
  locale: string;
  subCategory: string;
  isMobile?: boolean;
  isRedesigned?: boolean;
  t: (arg0: string) => string;
};

const Navigation = ({
  category,
  locale,
  homeUrl,
  title,
  isMobile,
  isRedesigned,
  t,
  subCategory
}: Props) => {
  return (
    <div
      className={classnames('section-navigation', {
        '_pt-9 _pt-md-11': isRedesigned
      })}
    >
      <Breadcrumb
        category={category}
        subCategory={subCategory}
        locale={locale}
        title={title}
        isMobile={isMobile}
        isRedesigned={isRedesigned}
        t={t}
        homeUrl={homeUrl}
      />
    </div>
  );
};

export default Navigation;
