import { RefObject } from 'react';

import Image from 'next/image';
import dynamic from 'next/dynamic';
import classnames from 'classnames';

import Modal, { HTMLHotModalElement } from 'src/components/ModalCosmos';
import { loaderImage } from 'utils/image';

const ShareButton = dynamic(() => import('components/@redesign/ShareButton'));

type Props = {
  modalRef: RefObject<HTMLHotModalElement>,
  imageSrc: string
  isMobile?: boolean
}

const ProductImageModal = (props: Props) => {
  const lockPageScroll = () => {
    if (window && document.body.style) {
      const scrollHeight = window.scrollY;

      document.body.style.position = 'fixed';
      document.body.style.top = `-${scrollHeight}px`;
    }
  }

  const unlockPageScroll = () => {
    if (window && document.body.style) {
      const scrollHeight = document.body.style.top;

      document.body.style.position = '';
      document.body.style.top = '';
      window.scrollTo(0, parseInt(scrollHeight || '0') * -1);
    }
  }

  return (
    <Modal ref={props.modalRef} onOpenModal={lockPageScroll} onCloseModal={unlockPageScroll}>
      <Modal.Body>
        <div className="_d-flex _justify-content-center _flex-column _w-full">
          <div className="product-image-modal__share-spacing">
            <ShareButton />
          </div>
          <div className={classnames('_mx-auto _my-8', {
            'product-image-modal__container': !props.isMobile
          })}>
            <Image
              src={props.imageSrc}
              alt="Illustration"
              width={536}
              height={536}
              loader={loaderImage}/>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default ProductImageModal
