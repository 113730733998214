import { EmployerAggregateRating } from 'schema-dts';
import { createCheckoutURL } from 'utils/checkout';
import { dateAddYear, formatLocale } from 'utils/date';

export const mountProductOffer = (
  product: ProductInfo,
  checkout: DataCheckoutProps
) => {
  const productCheckoutInfo = checkout?.data?.offer?.paymentInfoResponse;

  const productCheckoutLink = createCheckoutURL(
    product?.cta,
    product?.producerReferenceCode,
    product?.offer,
    checkout?.src,
    checkout?.sck,
    null,
    null
  );

  if (!productCheckoutInfo?.offerPrice?.value) {
    return null;
  }

  return {
    '@type': 'Offer',
    url: productCheckoutLink,
    priceCurrency: productCheckoutInfo?.offerCurrencyCode,
    priceValidUntil: formatLocale(dateAddYear(new Date(), 1)),
    price: productCheckoutInfo?.offerPrice?.value,
    seller: {
      '@type': 'Organization',
      name: product.hotmarter?.name
    },
    availability: 'https://schema.org/InStock'
  };
};

export const mountProductReview = (reviews) => {
  if (!reviews || !reviews?.totalReviews) return;

  return {
    '@type': 'AggregateRating' as 'AggregateRating',
    ratingValue: reviews?.rating.toFixed(1),
    reviewCount: reviews?.totalReviews
  };
};
