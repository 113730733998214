import styles from './style.module.scss';

type Props = {
  text: string;
  isRequired?: boolean;
  requiredMessage?: string;
  className?: string;
};

const Label = ({
  text,
  isRequired,
  requiredMessage,
  className,
  ...props
}: Props) => {
  return (
    <div className={`${styles.label} ${className}`} {...props}>
      {text}
      {isRequired && (
        <span className={styles.label__required}>{requiredMessage}</span>
      )}
    </div>
  );
};

export default Label;
