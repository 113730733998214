import React from 'react'

import PropTypes from 'prop-types'

import '@cosmos/form/form.css'
import '@cosmos/form/input-custom.css'
import '@cosmos/form/input-checkbox.css'
import classnames from 'classnames';
import styles from '../Label/style.module.scss';

import HelperText from '../HelperText'

const Checkbox = React.forwardRef(
  ({ id, label, checked, error, helperText, disabled, isRequired = false, className, inputClassName, labelClassName, ...props }, ref) => {
    const attributes = {
      ...(error ? { invalid: '' } : {}),
      ...(disabled ? { disabled: '' } : {}),
      ...(checked ? { checked: true } : { checked: false })
    }

    return (
      <div className={`hot-form hot-form--custom hot-form--checkbox ${className}`}>
        <input
          ref={ref}
          type="checkbox"
          className={`hot-form__input ${inputClassName}`}
          id={id}
          {...attributes}
          {...props}
        />
        <label className={`hot-form__label ${labelClassName}`} htmlFor={id}>
          {label}
          {isRequired && (
            <span className={classnames(styles.label__required, styles.label__required__small)}>*</span>
          )}
        </label>
        <HelperText error={error} helperText={helperText} />
      </div>
    )
  }
)

Checkbox.displayName = 'Checkbox'

Checkbox.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  checked: PropTypes.bool,
  error: PropTypes.string,
  helperText: PropTypes.string,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  inputClassName: PropTypes.string,
  labelClassName: PropTypes.string
}

Checkbox.defaultProps = {
  id: '',
  label: '',
  checked: false,
  error: '',
  helperText: '',
  disabled: false,
  className: '',
  inputClassName: '',
  labelClassName: ''
}

export default Checkbox
