export const isIframe = (text) => {
  const validation = `${text || ''}`.match(/^\<iframe/);
  return !!validation;
};

export const getIframeSrcURL = (text) => {
  try {
    const parser = new DOMParser();

    const parsedIframe = parser.parseFromString(text, 'text/html');
    let iFrame = parsedIframe.getElementsByTagName('iframe');

    // Read URL:
    return (iFrame && iFrame[0] && iFrame[0].src) || '';
  } catch (err) {
    console.error(err);
    return '';
  }
};
