import React, { memo } from 'react';
import PropTypes from 'prop-types'
import classnames from 'classnames';

import { LoadingTag } from 'components/Loading'

const Price = ({
  currency,
  amount,
  description,
  pricePreviousValue,
  discount,
  schedule,
  loading,
  className,
  vat,
  ctaIcon
}) => {
  if(loading) {
    return (
      <LoadingTag
        width={230}
        height={54}
      />
    )
  }

  return (
    <div className={classnames('price', { 'price-schedule': schedule }, className)}>
      {pricePreviousValue && discount !== 0 && pricePreviousValue !== amount && (
        <div className="price__previous">
          <span className="price__previous__label">{pricePreviousValue}</span>
          {discount && !ctaIcon &&
            <span className="price__previous__discount">
              <span className="price__previous__discount__label">-{discount}%</span>
            </span>
          }
          {ctaIcon && <img className="price__cta-icon" src={ctaIcon}/>}
        </div>
      )}
      <div className={classnames('price--value')}>
        {currency && <span className="price--value--currency">{currency}</span>}
        {amount && <span className="price--value--amount">{amount}</span>}
      </div>
      {description && <div className="price--description">{description}</div>}
      {vat && <div className="price--vat">{vat}</div>}
    </div>
  );
};

Price.propTypes = {
  currency: PropTypes.string,
  amount: PropTypes.string,
  description: PropTypes.string,
  pricePreviousValue: PropTypes.string,
  discount: PropTypes.number,
  schedule: PropTypes.bool,
  loading: PropTypes.bool,
  className: PropTypes.string,
  vat: PropTypes.string,
  ctaIcon: PropTypes.string
}

export default memo(Price);
