import React, { useContext } from 'react';
import { ProfileContext } from 'components/core/Provider/ProfileProvider';

type Props = {
  tag: string,
  complementary: string,
  schedule: boolean,
  className?: string,
  tabIndex?: number
  style?: any
}

const ProductFormat = ({ tag, complementary, schedule, className, tabIndex, style }: Props) => {
  const context = useContext(ProfileContext);

  if (!complementary) return null;

  return (
    <div
      className={`product-format ${
        context.checkout.opened ? context.checkout.classOpened : ''
      } ${schedule ? 'product-format__schedule' : ''}`}
      style={style}
    >
      <div className="product-format__info" tabIndex={tabIndex} aria-label={complementary}>{complementary}</div>
    </div>
  );
};

export default ProductFormat;
