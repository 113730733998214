type Props = {
  title: string
};

function DropdownMenuHeader({ title }: Props) {
  return (
    <hot-dropdown-menu-header slot="menu">
      {title}
    </hot-dropdown-menu-header>
  );
}

export default DropdownMenuHeader;
