import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import styles from './style.module.scss';

const Tooltip = (props) => {
  const { children, message } = props;

  return (
    <div className={styles.tooltip}>
      {children}
      {message &&
        <div className={styles["tooltiptext-block"]}>
          <span className={classnames(styles.tooltiptext)}>{message}</span>
        </div>
      }
    </div>
  )
}

Tooltip.propTypes = {
  children: PropTypes.any,
  message: PropTypes.string,
  position: PropTypes.string
};

export default Tooltip