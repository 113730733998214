import { ProfileContext } from '../core/Provider/ProfileProvider';
import React, { Fragment, useContext, useState, useEffect } from 'react';
import { withTranslation } from 'next-i18next';
import LinkButton from '../LinkButton';
import ServiceOnline from '../../@online_service/service';
import classNames from 'classnames'

const CalendarContainer = (props) => {
  const [seeTimes, setSeeTimes] = useState(false);
  const context = useContext(ProfileContext);
  const { language = null, ucode = null} = props;
  const { t, isMobile, modal = false } = props;
  const { online_service, checkout = {} } = context;
  const { sessionInfo = {} } = online_service;
  const { data = {} } = checkout;
  const hasAvailableDays = Array.isArray(sessionInfo?.availableDays) && sessionInfo?.availableDays.length > 0;
  const textButton = hasAvailableDays ? t('online_service.onlineServiceButton') : t('online_service.onlineServiceButtonOff');

  useEffect(() => {
    const { profile, online_service } = context;
    const { product = {} } = profile;
    const productCode = product?.ucode || ucode;

    if (productCode && !online_service?.sessionInfo?.availableDays) {
      ServiceOnline.getDates(productCode).then(response => {
        const {
          availableDays,
          attendanceTimesPerWeek,
          attendanceSlotSize,
          numberOfSessions,
          sessionFrequency,
          sessionType
        } = response

        context.actions.setSessionInfo({
          attendanceTimesPerWeek,
          numberOfSessions,
          sessionFrequency,
          sessionType,
          attendanceSlotSize,
          availableDays
        })

        const datesFiltered = availableDays && availableDays.map(date => new Date(date));
        if (datesFiltered && datesFiltered?.length >= 1) {
          context.actions.setDates(datesFiltered);
        } else {
          context.actions.setDates(null);
        }
      }).catch(_ => {
        context.actions.setDates(null);
        context.actions.setSessionInfo(null);
      })
    }
  }, [language])

  const openScheduler = () => {
    context.actions.openScheduler(!context.checkout.onlineService)
    setSeeTimes(true);
  };

  const openModalScheduler = () => {
    if (!context.checkout.modalOnlineService) {
      blockBody();
    }

    context.actions.openModalScheduler(!context.checkout.modalOnlineService);
  }

  const blockBody = () => {
    if (window) {
      document.body.classList.add('no-scroll');
      document.getElementsByClassName('hm-mktplace-header')[0]?.classList.add('hm-mktplace-header--hidden')
      document.getElementsByClassName('hm-mktplace-header__container')[0]?.setAttribute('style', 'display:none')
      document.getElementsByClassName('hm-mktplace-header')[0]?.classList.remove('hm-mktplace-header--show')
    }
  }

    return (
      <Fragment>
        {
          ((!context.checkout.onlineService || !seeTimes) && !modal) && (
            <LinkButton
              onClick={openScheduler}
              text={textButton}
              icon={'calendar-day'}
              theme={'service'}
              size={!isMobile ? 'lg' : 'md'}
              className={classNames('btn-see-times', { 'disabled': !hasAvailableDays })}
              prefix={'far'}
            >
              {data.checkoutClosed ? t('salesPage.cta.checkoutClosed') : t('online_service.onlineServiceButton')}
            </LinkButton>
          )
        }

        {
          modal && (
            <LinkButton
              onClick={openModalScheduler}
              text={textButton}
              icon={'calendar-day'}
              theme={'service'}
              size={!isMobile ? 'lg' : 'md'}
              className={classNames('btn-see-times modal', { 'disabled': !hasAvailableDays })}
              prefix={'far'}
              modal={true}
            >
              {data.checkoutClosed ? t('salesPage.cta.checkoutClosed') : t('online_service.onlineServiceButton')}
            </LinkButton>
          )
        }
      </Fragment>
    )
}

export default withTranslation()(React.memo(CalendarContainer));
