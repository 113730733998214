import Request from '@hotmart/request';
import config from '../../env.config';

const options = { headers: { application: 'Profile' } };

export const Constants = {
  PROFILE: 'profile',
  PRODUCT: 'product',
  OTHER_PRODUCTS: 'profile.other_products',
  PROFILE_DRAFT: 'profile.draft',
  PROFILE_PREVIEW: 'profile.preview',
  PROFILE_PREVIEW_BACKOFFICE: 'profile.preview.backoffice',
  PRODUCT_PREVIEW_BACKOFFICE: 'product.preview.backoffice',
  PROFILE_UNPUBLISHED: 'profile.unpublished',
  PROFILE_REFERENCE: 'profile.reference',
  PRODUCT_PREVIEW: 'product.preview',
  PRODUCT_DRAFT: 'product.draft',
  PRODUCT_UNPUBLISHED: 'product.unpublished',
  PRODUCT_REFERENCE: 'product.reference',
  PRODUCT_MODULES: 'product.modules',
  CHECKOUT: 'profile.get_checkout',
  PRICES: 'profile.get_prices',
  CHECKOUT_PRICE: 'profile.get_checkout_price',
  PRODUCT_PRICE: 'profile.get_product_price',
  CHECKOUT_BN: 'profile.get_checkout_BN',
  CHECKOUT_INTERNAL: 'profile.get_checkout_internal',
  PRODUCT_BOUGHT: 'product.bought',
  USER: 'user.vulcano',
  AUTHCHECK: 'auth.check',
  CHECKTOKEN: 'auth.check_token',
  PRODUCT_REVIEWS: 'profile.reviews',
  PRODUCT_REVIEWS_ANSWERS: 'profile.reviews_answers',
  PROFILE_ERROR_MOCK: 'profile.error_mock',
  FEATURES_SWITCH: 'profile.features_switch',
  PROFILE_PREVIEW_BACKOFFICE_INTERNAL: 'profile_internal.preview.backoffice',
  PROFILE_PREVIEW_INTERNAL: 'profile_internal.preview',
  PROFILE_DRAFT_INTERNAL: 'profile_internal.draft',
  PROFILE_UNPUBLISHED_INTERNAL: 'profile_internal.unpublished',
  PROFILE_REFERENCE_INTERNAL: 'profile_internal.reference',
  PROFILE_INTERNAL: 'profile_internal',
  PRODUCT_INTERNAL: 'product_internal',
  PRODUCT_PREVIEW_BACKOFFICE_INTERNAL: 'product_internal.preview.backoffice',
  PRODUCT_PREVIEW_INTERNAL: 'product_internal.preview',
  PRODUCT_DRAFT_INTERNAL: 'product_internal.draft',
  PRODUCT_UNPUBLISHED_INTERNAL: 'product_internal.unpublished',
  PRODUCT_REFERENCE_INTERNAL: 'product_internal.reference',
  FEATURES_SWITCH_INTERNAL: 'profile_internal.features_switch'
};

Request.register(config.envConfig.ENDPOINT, {
  get: {
    [Constants.PROFILE]: '/rest/v1/product/:slug/page',
    [Constants.OTHER_PRODUCTS]: '/rest/v1/product/:reference/page/other/products',
    [Constants.PROFILE_PREVIEW]: '/rest/v1/product/:slug/page/preview',
    [Constants.PROFILE_PREVIEW_BACKOFFICE]:
      '/rest/v1/product/:slug/page/preview?origin=backoffice',
    [Constants.PROFILE_DRAFT]: '/rest/v1/product/:slug/page/draft',
    [Constants.PROFILE_UNPUBLISHED]: '/rest/v1/product/:slug/page/unpublished',
    [Constants.PROFILE_REFERENCE]: '/rest/v1/product/:slug/:reference/page',
    [Constants.PRODUCT_BOUGHT]: '/rest/v1/internal/product/:id/bought',
    [Constants.PRODUCT]: '/v1/page/product/:slug',
    [Constants.PRODUCT_REFERENCE]: '/v1/page/product/:slug/:reference',
    [Constants.PRODUCT_PREVIEW]: '/v1/page/product/:slug/page/preview',
    [Constants.PRODUCT_DRAFT]: '/v1/page/product/:slug/page/draft',
    [Constants.PRODUCT_PREVIEW_BACKOFFICE]:
      '/v1/page/product/:slug/page/preview?origin=backoffice',
    [Constants.PRODUCT_UNPUBLISHED]: '/v1/page/product/:slug/page/unpublished',
    [Constants.PRODUCT_MODULES]: '/v2/product/:ucode/club/content?rows=:rows&moduleOrder=:page&offer=:offer'

  }
}, options);

Request.register(config.envConfig.ENDPOINT_CDN, {
  get: {
    [Constants.FEATURES_SWITCH]: '/v1/config/public'
  }
}, options);

Request.register(`${config.envConfig.APP}${config.currentEnv !== 'development' ? '/product' : ''}/api/`, {
  post: {
    [Constants.CHECKOUT_INTERNAL]: 'checkout/price?productId=:productId'
  }
})


Request.register(config.envConfig.CHECKOUT_PRICE, {
  post: {
    [Constants.CHECKOUT]: '/v1/checkoutPrice/loadByProduct',
    [Constants.CHECKOUT_BN]: '/v1/checkoutPrice/offerPrices'
  }
})

Request.register(config.envConfig.PRICES, {
  post: {
    [Constants.PRODUCT_PRICE]: '/public/v1/payment/checkout/load-products',
  }
})

Request.register(config.envConfig.CHECKOUT_LOAD, {
  post: {
    [Constants.CHECKOUT_PRICE]: '/v1/checkout/load',
  }
})

Request.register(config.envConfig.ENDPOINT_INTERNAL, {
  get: {
    [Constants.FEATURES_SWITCH_INTERNAL]: '/rest/v1/config/public',
    [Constants.PROFILE_INTERNAL]: '/rest/v1/product/:slug/page',
    [Constants.PROFILE_PREVIEW_INTERNAL]: '/rest/v1/product/:slug/page/preview',
    [Constants.PROFILE_PREVIEW_BACKOFFICE_INTERNAL]:
      '/rest/v1/product/:slug/page/preview?origin=backoffice',
    [Constants.PROFILE_DRAFT_INTERNAL]: '/rest/v1/product/:slug/page/draft',
    [Constants.PROFILE_UNPUBLISHED_INTERNAL]: '/rest/v1/product/:slug/page/unpublished',
    [Constants.PROFILE_REFERENCE_INTERNAL]: '/rest/v1/product/:slug/:reference/page',
    [Constants.PRODUCT_REFERENCE_INTERNAL]: '/v1/page/product/:slug/:reference',
    [Constants.PRODUCT_PREVIEW_INTERNAL]: '/v1/page/product/:slug/page/preview',
    [Constants.PRODUCT_UNPUBLISHED_INTERNAL]: '/v1/page/product/:slug/page/unpublished',
    [Constants.PRODUCT_DRAFT_INTERNAL]: '/v1/page/product/:slug/page/draft',
    [Constants.PRODUCT_INTERNAL]: '/v1/page/product/:slug',
    [Constants.PRODUCT_PREVIEW_BACKOFFICE_INTERNAL]:
      '/v1/page/product/:slug/page/preview?origin=backoffice',
  }
}, options);

Request.register(config.envConfig.ASK_ENDPOINT, {
  get: {
    [Constants.PRODUCT_REVIEWS]: '/api/v1/survey/product/:id/rating',
    [Constants.PRODUCT_REVIEWS_ANSWERS]: '/api/v1/survey/product/:id/answers'
  }
});

Request.register(config.envConfig.OAUTH, {
  get: {
    [Constants.AUTHCHECK]: '/user/me'
  }
});

Request.register(config.envConfig.CHECK_TOKEN, {
  get: {
    [Constants.CHECKTOKEN]: '/check_token'
  }
});

Request.register(
  config.envConfig.VLC,
  {
    get: {
      [Constants.USER]: '/userprofile/rest/v1/user/profile'
    }
  },
  options
);
