import React, { Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types'

import AvatarImage from 'components/Images/Avatar';
import { ProfileContext } from '../core/Provider/ProfileProvider';
import { mountPriceTitle } from 'utils/checkout';

import Price from '../Price';
import { i18n } from 'next-i18next';
import SessionPackageInfo from '../SessionPackageInfo';
import { isSessionPackage } from '../../utils/sessions';
import dynamic from 'next/dynamic';
import IconsImage from '../Images/Icons';
import { loaderStaticImage } from '../../utils/image';

const Calendar = dynamic(() => import('components/Calendar'))

const Times = loaderStaticImage({src: 'static/images/icons/times.svg' });
const ChevronLeft = loaderStaticImage({src: '/static/images/icons/chevron-left.svg' });

class CalendarModal extends PureComponent {
  static contextType = ProfileContext;

  constructor(props) {
    super(props);
  }

  unblockBody = () => {
    if (window) {
      document.body.classList.remove('no-scroll');
      document.getElementsByClassName('hm-mktplace-header')[0]?.classList.add('hm-mktplace-header--show')
      document.getElementsByClassName('hm-mktplace-header__container')[0]?.setAttribute('style', 'display:block')    }
  }

  closeModal = () => {
    const { checkout } = this.context;
    this.unblockBody()
    this.context.actions.openModalScheduler(!checkout.modalOnlineService)
  }

  render() {
    const { t, buyButtonOnClick } = this.props;
    const { profile, checkout, online_service } = this.context;
    const { data } = checkout;
    const { products = [] } = data;
    const { offer = {}, maxChargeCycles, productType } = products.length >= 1 ? products[0] : {};
    const { paymentInfoResponse = {} } = offer;
    const { product = {} } = profile;
    const { productOwner = {}, hotmarter = {} } = product;
    const ownerHotmarter = productOwner && productOwner.name ? productOwner : hotmarter;
    const avatarFinal = ownerHotmarter?.avatarFinal || loaderStaticImage({ src: 'static/images/placeholder/avatar.png'})

    let title = offer ? mountPriceTitle(paymentInfoResponse, maxChargeCycles, productType, t) : '';

    let discountValue, coupon;

    return (
      <Fragment>
        <div className="calendar-modal__header">
          <IconsImage src={loaderStaticImage({ src: 'static/images/placeholder/logo-hm.svg' } )} width={20} height={20} className="calendar-modal__header-image" quality={10} priority={false} />
          <div onClick={this.closeModal}>
            <IconsImage
              src={Times}
              width={20}
              height={20}
            />
          </div>
        </div>
        <div className="calendar-modal__container">
          <div className="calendar-modal__title">
            <div className="calendar-modal__avatar">
              <AvatarImage src={avatarFinal} width={46} height={46} />
            </div>

            <div className="calendar-modal__title-price">
              <Price
                currency=""
                amount={title.priceTitle}
                description={title.priceRecurrence}
                pricePreviousValue={title.pricePreviousValue}
                discount={discountValue}
                schedule={checkout.onlineService || checkout.modalOnlineService}
              />

              {
                title && title.infoPrice && (
                  <div className={`copy-text truncate ${(checkout.onlineService || checkout.modalOnlineService)
                    ? 'price-copy-schedule' : ''}`}>
                    {title.infoPrice}
                  </div>
                )
              }

              {
                isSessionPackage(this.context) && (
                  <SessionPackageInfo
                    mobile
                    t={t}
                    info={this.context.online_service.sessionInfo} />
                )
              }
            </div>
          </div>

          <div className="calendar-modal__button">
            <a onClick={this.closeModal}>
              <IconsImage
                src={ChevronLeft}
                width={62}
                height={62}
              />
              {t('online_service.back_modal')}
            </a>
          </div>
        </div>

        <hr className="calendar-modal__divisor" />

        <div className="calendar-modal__container calendar">
          <Calendar
            startDate={new Date()}
            highlightDays={online_service.highlightDays}
            availableHours={online_service.availableHours}
            buyButtonOnClick={buyButtonOnClick}
            locale={i18n.language}
            checkout={checkout}
            profile={profile}
            modal={true}
          />
        </div>

      </Fragment>
    )
  }
}

CalendarModal.propTypes = {
  t: PropTypes.func,
  buyButtonOnClick: PropTypes.func
}

export default CalendarModal;
