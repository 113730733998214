import React from 'react';
import PropTypes from 'prop-types';

const Tab = (props) => {
  const { isTabActive, label, dataName } = props;
  const onClick = () => {
    const { onClick, dataName, name } = props;

    onClick(dataName, name);
  };

  let className = 'tab-list-item';

  if (isTabActive) {
    className += ' tab-list-active';
  }

  return (
    <li>
      <hot-nav-item
        class={`${isTabActive ? 'hot-nav__item--active' : ''}`}
        onClick={onClick}
        onKeyPress={onClick}
        data-name={dataName}
        tabIndex={props.tabIndex}
      >
        {label}
      </hot-nav-item>
    </li>
  );
};

Tab.propTypes = {
  isTabActive: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired
};

export default Tab;
