import React, { ReactNode, useContext } from 'react';
import classnames from 'classnames';
import { productQueryString } from '../../../constants';
import { ProfileContext } from '../../core/Provider/ProfileProvider';
import Utils from '../../../global/scripts/utils';

const generateProductQS = (qs) => {
  let productQS = '';

  if (Array.isArray(qs) && qs.length > 0) {
    const QSArray = [];
    qs.map((qs) => {
      QSArray.push(`${qs.key}=${qs.value}`);
    });
    productQS = `?${QSArray.join('&')}`;
  }
  return productQS;
};

const checkQSParam = (isSeasonProduct) => {
  const context = useContext(ProfileContext);
  const { checkout } = context;

  let params = [];

  if (checkout?.src) params.push({ key: 'src', value: checkout.src });

  if (isSeasonProduct) params.push(productQueryString.PRODUCT_SEASON[0]);

  if (checkout?.sck === 'HOTMART_SITE')
    params.push(productQueryString.PRODUCT_MARKETPLACE[0]);
  else if (checkout?.sck) params.push({ key: 'sck', value: checkout.sck });
  else params.push(productQueryString.PRODUCT_PAGE[0]);

  return params;
};

type ProductLinkProps = {
  children: any;
  slug: string;
  className?: string;
  producerReferenceCode: string;
  isSeasonProduct?: boolean;
  locale?: string;
  onClick?: () => void;
  ariaLabel?: string;
};

const ProductLink = ({
  children,
  slug,
  className,
  producerReferenceCode,
  isSeasonProduct,
  locale,
  onClick,
  ariaLabel
}: ProductLinkProps) => {
  if (slug) {
    const param = checkQSParam(isSeasonProduct);
    const qs = generateProductQS(param);
    const language = Utils.verifyLanguage(locale);
    const productURL = `${Utils.mountMainUrlSalesPage(
      language
    )}/${slug}/${producerReferenceCode}${qs}`;

    return (
      <a
        href={productURL}
        className={classnames('product-link', className)}
        onClick={onClick}
        aria-label={ariaLabel}
      >
        {children}
      </a>
    );
  }
  return children;
};

export default ProductLink;
