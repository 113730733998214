import React, { useState } from 'react';

import { withTranslation } from 'next-i18next';

import IconsImage from '../Images/Icons';
import { loaderStaticImage } from '../../utils/image';
import Button from './default';

const Arrow = loaderStaticImage({ src: 'static/images/icons/arrow.svg' });
const ArrowLightDown = loaderStaticImage({
  src: 'static/images/icons/arrow-light-down.svg'
});
const ArrowLight = loaderStaticImage({
  src: 'static/images/icons/arrow-white.svg'
});

type Props = {
  variation?: string;
  onClick: () => void;
  t: (arg0: string) => string;
  complement?: string;
  className?: string;
  wrapperClassName?: string;
  label?: string;
  readMore?: boolean;
  id?: string;
  dataTestId?: string;
  size?: string;
  disabled?: boolean;
  isRedesign?: boolean;
};

const ReadMore = ({
  complement = '',
  className = '',
  wrapperClassName = '',
  label = 'show',
  onClick,
  readMore,
  t,
  variation,
  id,
  dataTestId,
  size,
  disabled,
  isRedesign
}: Props) => {
  const [buttonHover, setButtonHover] = useState(false);
  const readMoreText = readMore
    ? t(`product.${label}_less`)
    : t(`product.${label}_more`);

  const toggleButtonHover = () => {
    setButtonHover(!buttonHover);
  };

  return (
    <Button
      onClick={onClick}
      onMouseEnter={toggleButtonHover}
      onMouseLeave={toggleButtonHover}
      variation={!buttonHover ? variation : 'primary'}
      size={size}
      className={className}
      wrapperClassName={wrapperClassName}
      id={id}
      dataTestId={dataTestId}
      aria-label={`${readMoreText} ${complement}`}
      disabled={disabled || false}
    >
      {readMoreText}
      <IconsImage
        src={isRedesign ? ArrowLightDown : !buttonHover ? Arrow : ArrowLight}
        className={`arrow ${readMore ? 'arrow-down' : 'arrow-up'}`}
        width={isRedesign ? 16 : 14}
        height={isRedesign ? 16 : 14}
      />
    </Button>
  );
};

export default withTranslation()(React.memo(ReadMore));
