import Utils from '../../global/scripts/utils';
import { sendProductDetailView } from 'utils/events';
import Service from '../../@profile/service';

const sendErrorCheckoutProductDetailView = (product, offerDetail) => {
  sendProductDetailView(product, offerDetail, 'Error');
};

const sendClosedCheckoutProductDetailView = (product, offerDetail) => {
  sendProductDetailView(product, offerDetail, 'No');
};

type ProductOfferDetails = {
  creditCardPaymentMethodList: [] | undefined;
  offer: {};
  productType: string;
  maxChargeCycles: string | undefined;
  locale: string | undefined;
  customizationResponse: Record<string, string> | undefined;
  newCheckout: boolean;
};

const getProductPriceGateway = async (
  profile: Profile,
  query: Record<string, string>,
  ip: string
) => {
  if (!profile?.salesEnabled) {
    sendClosedCheckoutProductDetailView(profile?.product, {});

    return {
      creditCardPaymentMethodList: undefined,
      offer: '',
      productType: '',
      maxChargeCycles: '',
      locale: '',
      customizationResponse: undefined,
      newCheckout: false,
      checkoutClosed: true
    };
  }

  const response = await Service.getCheckoutPrice(profile, query, ip);
  const productPriceDetails = response?.productsResponse[0]?.products[0];

  return {
    creditCardPaymentMethodList: response?.creditCardPaymentMethodList,
    offer: productPriceDetails?.offer,
    productType: productPriceDetails?.type,
    maxChargeCycles: productPriceDetails?.maxChargeCycles,
    locale: response?.productsResponse[0]?.locale || '',
    customizationResponse: productPriceDetails?.customizationResponse,
    newCheckout: true
  };
};

const getProductPriceCheckout = async (
  profile: Profile,
  query: Record<string, string>,
  ip: string
) => {
  const response = await Service.getCheckout(profile, query, ip);
  const productPriceDetails = response?.products[0];

  return {
    creditCardPaymentMethodList: response?.creditCardPaymentMethodList,
    offer: productPriceDetails?.offer,
    productType: productPriceDetails?.productType,
    maxChargeCycles: productPriceDetails?.maxChargeCycles,
    locale: '',
    customizationResponse: productPriceDetails?.customizationResponse,
    newCheckout: false
  };
};

export const getProductOfferDetails = async (
  profile: Profile,
  query: any,
  ip: string,
  isProductPriceGatewayActive: boolean
) => {
  let productOffer = {
    creditCardPaymentMethodList: undefined,
    offer: '',
    productType: '',
    maxChargeCycles: '',
    locale: '',
    customizationResponse: undefined,
    newCheckout: false
  } as ProductOfferDetails;

  if (isProductPriceGatewayActive) {
    productOffer = await getProductPriceGateway(profile, query, ip);
  } else {
    productOffer = await getProductPriceCheckout(profile, query, ip);
  }

  return productOffer;
};

export const getProductPrice = async (
  context: TypeContext,
  profile: Profile,
  query: Record<string, string>,
  ip: string
) => {
  context.actions.setCheckoutLoading(true);
  const isBlackNovember = Utils.isBlackNovember(profile.product);
  const blackNovemberCoupon = isBlackNovember ? profile.product.coupon.code : null
  const coupon = query?.coupon || blackNovemberCoupon;
  const isProductPriceGatewayActive = profile?.productsPriceToggle || false;
  query.off = query.off ?? profile?.product?.offer;
  const { off, src, sck, hotfeature } = query;
  let checkout = null;

  try {
    const checkoutOffer = await getProductOfferDetails(
      profile,
      query,
      ip,
      isProductPriceGatewayActive
    );

    checkout = { ...context.checkout, ...checkoutOffer };
    context.actions.setCheckout(checkout, off, src, sck, coupon, hotfeature);

    sendProductDetailView(
      profile?.product,
      checkout?.offer?.paymentInfoResponse?.offerFullPrice?.value
    );

    context.actions.setCheckoutLoading(false);

    return checkout;
  } catch (error) {
    const { response = {} } = error;
    const { status, data } = response;

    if (
      status === 460 &&
      response?.data?.error ===
        'order.checkout.orderprocessor.hotmart_checkout_error_hmc_049'
    ) {
      sendClosedCheckoutProductDetailView(
        profile?.product,
        checkout?.offer?.paymentInfoResponse?.offerFullPrice?.value
      );
    } else {
      sendErrorCheckoutProductDetailView(
        profile?.product,
        checkout?.offer?.paymentInfoResponse?.offerFullPrice?.value
      );
    }

    const errorTreated = Utils.treatCheckoutError(
      status,
      data,
      profile.product.format
    );
    checkout = { ...context.checkout, ...errorTreated, ...checkout };
    context.actions.setCheckout(checkout, off, src, sck, coupon, hotfeature);
    context.actions.setCheckoutLoading(false);
  }
};
