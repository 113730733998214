import Utils from 'global/scripts/utils';

export const encodeHtmlEntity = (str) => {
  const buf = [];
  for (let i = str.length - 1; i >= 0; i--) {
    buf.unshift(['&#', str[i].charCodeAt(), ';'].join(''));
  }
  return buf.join('');
};
export const zeroLeft = (value, count) => {
  if (typeof value === 'undefined') return '';
  return value.toString().padStart(count, '0');
};

export const validateEmail = (value) => {
  var re = /\S+@\S+\.\S+/;
  return re.test(value);
};

export const validateName = (value) => {
  var re = /^[a-zA-Z ]{4,30}$/;
  return re.test(value);
};

export const getEnvironment = (sck) => {
  return sck && sck === 'HOTMART_SITE' ? 'Marketplace' : 'Product';
};

export const isHighRated = (rating) => {
  return rating >= 3.5;
};

export const objectKeysToLowerCase = (origObj) => {
  return Object.keys(origObj).reduce(function (newObj, key) {
    let val = origObj[key];
    let newVal =
      typeof val === 'object' ? Utils.objectKeysToLowerCase(val) : val;
    newObj[key.toLowerCase()] = newVal;
    return newObj;
  }, {});
};
