import { PropsWithChildren, MouseEvent } from 'react';

import classnames from 'classnames';

export interface Props {
  onClick?: (event: MouseEvent) => void,
  selected?: boolean,
  icon?: string,
  iconPrefix?: string
}

function DropdownMenuItem({
  children,
  onClick,
  icon,
  selected = false,
  iconPrefix = 'far',
  ...rest
}: PropsWithChildren<Props>) {
  const classes = classnames({ 'is-selected': selected });

  return (
    <hot-dropdown-menu-item
      slot="menu"
      class={classes}
      onClick={onClick}
      {...rest}
    >
      {children}
    </hot-dropdown-menu-item>
  );
}

export default DropdownMenuItem;
