import React, { useEffect, useState, useContext } from 'react';

import { useRouter } from 'next/router';
import dynamic from 'next/dynamic';
import classnames from 'classnames';

import { loaderStaticImage } from 'utils/image';
import { getTopificationRouter } from 'utils/url';
import { DropdownSide } from 'components/Dropdown/types';
import { ProfileContext } from 'components/core/Provider/ProfileProvider';
import IconsImage from 'components/Images/Icons';
import Utils from '../../global/scripts/utils';
import { jsonLdScriptProps } from 'react-schemaorg';
import { TOPIC_KEY, TOPIFICATION_KEY } from 'utils/topification/topification';

const BackButton = loaderStaticImage({
  src: 'static/images/icons/back_button.png'
});
const Home = loaderStaticImage({ src: 'static/images/icons/home.svg' });
const Arrow = loaderStaticImage({ src: 'static/images/icons/arrow-right.png' });

const Dropdown = dynamic(() => import('components/Dropdown'), { ssr: false });
const DropdownMenuItem = dynamic(() => import('components/Dropdown/MenuItem'), {
  ssr: false
});
const DropdownMenuHeader = dynamic(
  () => import('components/Dropdown/MenuHeader'),
  { ssr: false }
);
const DropdownEllipsisButton = dynamic(
  () => import('components/Dropdown/EllipsisButton'),
  { ssr: false }
);

type Props = {
  homeUrl: string;
  category?: string;
  subCategory?: string;
  title?: string;
  isMobile?: boolean;
  isRedesigned?: boolean;
  locale?: string;
  t: (arg0: string) => string;
};

type Backdata = {
  topBackButtonText: string | null;
  topBackButtonURL?: boolean | null;
};

function Breadcrumb({
  homeUrl,
  category,
  subCategory,
  isMobile,
  isRedesigned,
  locale,
  title,
  t
}: Props) {
  const router = useRouter();
  const context = useContext(ProfileContext);
  const sck = context?.query?.sck;

  const categoryURLPage = getTopificationRouter({
    language: locale as Language,
    topification: category as TOPIFICATION_KEY
  });
  const subCategoryURLPage = getTopificationRouter({
    language: locale as Language,
    topification: category as TOPIFICATION_KEY,
    topic: subCategory as TOPIC_KEY
  });

  const [backdata, setBackdata] = useState<Backdata>({
    topBackButtonText: null,
    topBackButtonURL: null
  });

  useEffect(() => {
    let lastUrl;
    try {
      lastUrl = document.referrer.length > 0 && new URL(document.referrer);
    } catch (err) {}

    if (
      lastUrl &&
      lastUrl.host?.replace('www.', '') ===
        window.location.host?.replace('www.', '')
    ) {
      const path = lastUrl.pathname;
      if (path === window.location.pathname) {
        // se o path atual for igual ao referal
        setBackdata({
          topBackButtonText: null
        });
      } else if (history.length > 1) {
        setBackdata({
          topBackButtonText: t('general.back'),
          topBackButtonURL: true
        });
      }
    } else {
      setBackdata({
        topBackButtonText: null
      });
    }
  }, [sck, t]);

  const mountBreadcrumbList = () => {
    const backSection = t('breadcrumb.back');
    const home = t('breadcrumb.home');

    const items = [];
    let finalBreadcrumbList = [];

    if (home) {
      items.push({
        name: home,
        url: homeUrl
      });
    }

    if (category) {
      items.push({
        name: category,
        url: categoryURLPage
      });
    }

    if (subCategory) {
      items.push({
        name: subCategory,
        url: subCategoryURLPage
      });
    }

    if (title) {
      items.push({
        name: title
      });
    }

    items.forEach((item, index) => {
      finalBreadcrumbList.push({
        '@type': 'ListItem',
        position: index + 1,
        name: Utils.sanitize(item.name),
        ...(item.url ? { item: item.url } : {})
      });
    });

    return finalBreadcrumbList;
  };

  return (
    <>
      <script
        {...jsonLdScriptProps({
          '@context': 'https://schema.org',
          '@graph': [
            {
              '@type': 'Organization',
              name: 'Hotmart Marketplace',
              url: homeUrl,
              logo: 'https://hotmart.com/product/static/images/placeholder/logo-hm.svg'
            },
            {
              '@type': 'BreadcrumbList',
              itemListElement: mountBreadcrumbList()
            }
          ]
        })}
      />
      <div
        role="navigation"
        className={classnames('_w-full', {
          '_border-bottom': isRedesigned
        })}
      >
        <div
          className={classnames('breadcrumbs', {
            'breadcrumbs--redesigned _mx-auto _w-full': isRedesigned
          })}
        >
          {backdata?.topBackButtonText && (
            <button type="button" onClick={() => router.back()}>
              <div className="breadcrumbs__section short">
                <div className="_h-4 _w-4 _text-gray-900">
                  <IconsImage
                    src={BackButton}
                    width={16}
                    height={14}
                    className="black-november-banner__tag"
                  />
                </div>
                <span className="breadcrumbs__icon">
                  {t('breadcrumb.back')}
                </span>
              </div>
            </button>
          )}
          {!isMobile && (
            <a href={homeUrl}>
              <div className="breadcrumbs__section breadcrumbs__home short">
                <div className="_h-4 _w-4 _text-gray-900">
                  <IconsImage
                    src={Home}
                    width={16}
                    height={14}
                    className="black-november-banner__tag"
                  />
                </div>
                <span className="breadcrumbs__icon breadcrumbs__text">
                  {t('breadcrumb.home')}
                </span>
              </div>
            </a>
          )}
          {!isMobile && category && (
            <a href={categoryURLPage}>
              <div className="breadcrumbs__section breadcrumbs__section__categories">
                <span
                  className={classnames(
                    'breadcrumbs__category-label breadcrumbs__text',
                    { '_font-weight-bold': isRedesigned }
                  )}
                >
                  {t(`topification.${category}`)}
                </span>
                <IconsImage
                  src={Arrow}
                  width={8}
                  height={8}
                  className="black-november-banner__tag"
                />
              </div>
            </a>
          )}
          {!isMobile && subCategory && (
            <a href={subCategoryURLPage}>
              <div className="breadcrumbs__section breadcrumbs__section__categories">
                <span
                  className={classnames(
                    'breadcrumbs__category-label breadcrumbs__text',
                    { '_font-weight-bold': isRedesigned }
                  )}
                >
                  {t(`topic.${subCategory}`)}
                </span>
                <IconsImage
                  src={Arrow}
                  width={8}
                  height={8}
                  className="black-november-banner__tag"
                />
              </div>
            </a>
          )}
          <div
            className={classnames(
              'breadcrumbs__section _w-full _justify-content-between'
            )}
          >
            {title && (
              <span
                className={classnames('breadcrumbs__category-label__ellipsis', {
                  '_font-weight-bold': isRedesigned
                })}
              >
                {title}
              </span>
            )}
          </div>
          {isMobile && (
            <div className="breadcrumbs__dropdown">
              <Dropdown className="dropdown" side={DropdownSide.RIGHT}>
                <DropdownEllipsisButton className="dropdown-menu" />
                {category && (
                  <>
                    <DropdownMenuHeader title={t('general.categories')} />
                    <DropdownMenuItem>
                      <a href={categoryURLPage}>
                        <div className="_d-flex">
                          <span className="dropdown-item">
                            {t(`topification.${category}`)}
                          </span>
                        </div>
                      </a>
                    </DropdownMenuItem>
                  </>
                )}
                {subCategory && (
                  <>
                    <DropdownMenuHeader title={t('general.subcategories')} />
                    <DropdownMenuItem>
                      <a href={subCategoryURLPage}>
                        <div className="_d-flex">
                          <span className="dropdown-item">
                            {t(`topic.${subCategory}`)}
                          </span>
                        </div>
                      </a>
                    </DropdownMenuItem>
                  </>
                )}
                <>
                  <DropdownMenuHeader title={t('category.general.name')} />
                  <DropdownMenuItem>
                    <a href={homeUrl}>
                      <div className="_d-flex">
                        <div className="_h-4 _w-4 _text-gray-900">
                          <IconsImage src={Home} width={14} height={14} />{' '}
                        </div>
                        <span className="dropdown-item dropdown-item--icon">
                          {t('breadcrumb.home')}
                        </span>
                      </div>
                    </a>
                  </DropdownMenuItem>
                </>
              </Dropdown>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default React.memo(Breadcrumb);
