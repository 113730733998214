import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import styles from './style.module.scss';
import IconsImage from '../Images/Icons';
import { ProfileContext } from 'components/core/Provider/ProfileProvider';
import { loaderStaticImage } from '../../utils/image';
import { formLeadSendStatus } from '../../constants';
import Header from './Header';
import dynamic from 'next/dynamic';

const Form = dynamic(()=> import('./form'), { ssr: false });

const LaunchProduct = (props) => {
  const { isMobileDevice, t, showFooter = false } = props;
  const context = useContext(ProfileContext);
  const { profile } = context;
  const [finish, setFinish] = useState(0);

  const congratulationsRender = () => {
    return (
      <>
        {profile?.product?.launchType && (
          <div className={styles.title_container_finish}>
            <div className={styles.title_c}>{t('launch.finish_title')}</div>
          </div>
        )}
        <div className={styles.subtitle}>
          {finish === formLeadSendStatus.SUCCESS
            ? t('launch.finish_subtitle')
            : t('order.checkout.orderprocessor.hotpay_error_022')}
        </div>
        <button
          className={styles['back-block']}
          onClick={() => setFinish(formLeadSendStatus.INITIAL)}
        >
          <IconsImage
            width={12}
            height={12}
            src={loaderStaticImage({
              src: 'static/images/icons/arrow-left-gray.png'
            })}
          />{' '}
          <span className="_ml-1">{t('launch.finish_back')}</span>
        </button>
      </>
    );
  };

  if (context?.profile?.salesEnabled) {
    return null;
  }

  return (
    <div className={styles.section__launch}>
      <div className={styles.section__form}>
        <div
          className={classnames({
            section: isMobileDevice
          })}
        >
          {!finish && <Header product={profile?.product} t={t} />}
          {finish !== formLeadSendStatus.INITIAL && congratulationsRender()}
        </div>
        {!finish && profile?.product?.launchType !== 'L' && (
          <Form
            t={t}
            isMobileDevice={isMobileDevice}
            setFinish={setFinish}
            product={profile?.product}
            tokenKlickSend={profile?.product?.tokenKlickSend}
            showFooter={showFooter}
          />
        )}
      </div>
    </div>
  );
};

LaunchProduct.propTypes = {
  isMobileDevice: PropTypes.bool,
  profile: PropTypes.object,
  t: PropTypes.func
};

export default React.memo(LaunchProduct);
