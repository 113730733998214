import React, { useEffect, useRef } from 'react';
import { sendAnalyticsEvent } from 'utils/datalayer';
import dynamic from 'next/dynamic';

import PropTypes from 'prop-types';
import classNames from 'classnames';
import { loaderStaticImage } from 'utils/image';
import '@hotmart/cosmos/dist/collapse';
const IconsImage = dynamic(() => import('components/Images/Icons'), {
  ssr: false
});

const ExtraContentIcon = loaderStaticImage({
  src: 'static/images/icons/extra_content.svg'
});

type AccordionProps = {
  children: any;
  className: string;
  sectionName?: string;
  subtitle?: string;
  lastItem?: any;
  title: string;
  index?: number;
  uuid?: string;
  productId?: number;
  environment?: string;
  isModuleExtraPurchased?: boolean;
  test?: string;
};

const Accordion = ({
  children,
  className,
  sectionName = '',
  subtitle,
  lastItem,
  title,
  isModuleExtraPurchased = false,
  index,
  uuid,
  productId,
  environment,
  test = ''
}: AccordionProps) => {
  const collapseRef = useRef(null);

  useEffect(() => {
    if (collapseRef?.current && className === 'redesigned-modules') {
      const shadowRootCollapse = collapseRef.current?.shadowRoot;
      const style = document.createElement('style');
      style.innerHTML = `.hot-collapse__title { padding-left: 0 !important;}`;
      if (index) {
        style.innerHTML += `.hot-collapse__item {border-top: 0.0625rem solid #e6e9ed !important;}`;
      }
      shadowRootCollapse?.appendChild(style);
    }
  }, []);

  const clickAccordion = () => {
    if (uuid?.includes?.('faq-ia')) {
      sendAnalyticsEvent({
        event: 'interaction',
        event_category: `${environment}:Product`,
        event_action: 'Click:FaqIA',
        event_label: `Product:${productId}`
      });
    }
  };

  return (
    <hot-collapse ref={collapseRef} id={test}>
      <div
        className="accordion__item"
        tabIndex={0}
        role="button"
        ref={lastItem}
        onClick={() => clickAccordion()}
        aria-label={`${sectionName} ${title}`}
      >
        <div className="accordion__heading">
          <div className="_d-flex _flex-row _align-items-baseline">
            {subtitle && <span className="accordion__section">{subtitle}</span>}
            <div className="_d-flex">
              <span
                className={classNames('accordion__title', {
                  'accordion__title--extra': isModuleExtraPurchased
                })}
              >
                {title}
              </span>
              {isModuleExtraPurchased && (
                <IconsImage
                  className="accordion__extra"
                  src={ExtraContentIcon}
                  width={15}
                  height={15}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <div slot="collapsed">{children}</div>
    </hot-collapse>
  );
};

Accordion.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node.isRequired
};

export default React.memo(Accordion);
