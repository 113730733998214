import { useEffect, useRef, useState } from 'react';
import IconsImage from '../../Images/Icons';
import { loaderStaticImage } from '../../../utils/image';
import { reviewsFilter } from '../../../constants';
import classnames from 'classnames';

const FilterIcon = loaderStaticImage({ src: 'static/images/icons/filter.svg' });
const RedesignedFilterIcon = loaderStaticImage({ src: 'static/images/icons/redesigned-filter.svg' });

const Filter = ({
  reviews,
  sort,
  setFilter,
  t,
  isRedesigned = false,
  showNewLabel = false
}) => {
  const [opened, setOpened] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const orderFilter = useRef(null);
  const allFilter = useRef(null);
  const negativesFilter = useRef(null);
  const textFilter = t('reviews.filters.filter');

  useEffect(() => {
    deactiveFilter(false);
    setIsDisabled(false);
  }, [reviews]);

  useEffect(() => {
    const handleClickFilter = (event) => {
      if (orderFilter?.current && !orderFilter.current.contains(event.target)) {
        deactiveFilter(false);
      }
    };

    document.addEventListener('mousedown', handleClickFilter);
    return () => {
      document.removeEventListener('mousedown', handleClickFilter);
    };
  }, [orderFilter]);

  const activeFilter = () => {
    setOpened(!opened);

    setTimeout(() => {
      allFilter?.current?.focus();
    }, 100);
  };

  const deactiveFilter = (filter) => {
    setOpened(filter);
  };

  const filter = (filter) => {
    setFilter(filter);
    setIsDisabled(true);
  };

  const removeFocus = (event) => {
    if (negativesFilter?.current === event?.target) {
      deactiveFilter(false);
    }
  };

  const renderFilter = () => {
    return (
      <div
        className={classnames('ordering-filter', {
          'ordering-filter__spacing': !isRedesigned
        })}
        ref={orderFilter}>
        <button
          className={classnames('ordering-filter__button', {
            'ordering-filter__button-active': opened
          })}
          onClick={activeFilter}
          aria-label={`${textFilter} ${t('reviews.title')}`}
        >
          <div className={classnames('ordering-filter__button-text', {
            '_text-black _text-decoration-underline _mr-2': isRedesigned || showNewLabel
          })}>
            {textFilter}
          </div>
          <IconsImage
            src={isRedesigned || showNewLabel ? RedesignedFilterIcon : FilterIcon}
            width={62}
            height={62}
            className="avatar-icon"
          />
        </button>
        {opened && (
          <ul
            className={classnames('ordering-filter__options', {
              'ordering-filter__options-active': opened,
              'ordering-filter__options-disabled': isDisabled
            })}
            onKeyDown={removeFocus}
          >
            <li
              className={classnames('ordering-filter__options-item', {
                active: sort === reviewsFilter.ALL
              })}
              ref={allFilter}
              tabIndex={0}
              aria-label={`${textFilter} ${t('reviews.filters.all')}`}
              onClick={() => filter(reviewsFilter.ALL)}
            >
              {t('reviews.filters.all')}
            </li>
            <li
              className={classnames('ordering-filter__options-item', {
                active: sort === reviewsFilter.POSITIVE
              })}
              tabIndex={0}
              aria-label={`${textFilter} ${t('reviews.filters.positives')}`}
              onClick={() => filter(reviewsFilter.POSITIVE)}
            >
              {t('reviews.filters.positives')}
            </li>
            <li
              className={classnames('ordering-filter__options-item', {
                active: sort === reviewsFilter.NEGATIVE
              })}
              ref={negativesFilter}
              tabIndex={0}
              aria-label={`${textFilter} ${t('reviews.filters.negatives')}`}
              onClick={() => filter(reviewsFilter.NEGATIVE)}
            >
              {t('reviews.filters.negatives')}
            </li>
          </ul>
        )}
        <div
          className={classnames('ordering-filter__box-shadow', {
            'ordering-filter__box-shadow-active': opened
          })}
        ></div>
      </div>
    );
  };

  return <>{renderFilter()}</>;
};

export default Filter;
