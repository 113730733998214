import config from '../../env.config';

const useUrlAuth = ({ CAS_IS_ENABLED = true }) => {
  const storage = localStorage ? localStorage : sessionStorage ? sessionStorage : '';
  const url = new URL(location.href);

  const setRedirectUrl = () => {
    storage.setItem('url_redirect', `${location?.href}${url.search ? '&system=product_page' : '?system=product_page'}`);
  }


  const loginUrl = () => {
    if (storage) {
      setRedirectUrl();
    }

    if (CAS_IS_ENABLED) {
      return `${config.envConfig.AUTH_HOTMART}/login?redirect_uri=${url?.href}${url.search ? '&system=product_page' : '?system=product_page'}`
    } else {
      return `${config.envConfig.LOGIN}/login?redirect=${url?.href}`
    }
  }

  const signUpUrl = () => {
    if (storage) {
      storage.setItem('product_page_redirect_url', url?.href);
    }

    if (CAS_IS_ENABLED) {
      return `${config.envConfig.AUTH_HOTMART}/signup?redirect_uri=${location?.href}${url.search ? '&system=product_page' : '?system=product_page'}`
    } else {
      return `${config.envConfig.LOGIN}/signup?redirect=${url?.href}${url.search ? '&buyerActive=true' : '?buyerActive=true'}`
    }
  }

  const logoutUrl = () => {
    if (storage) {
      setRedirectUrl()
    }

    if (CAS_IS_ENABLED) {
      return `${config.envConfig.AUTH_HOTMART}/logout?redirect_uri=${location?.href}${url.search ? '&system=product_page' : '?system=product_page'}`
    } else {
      return `${config.envConfig.LOGIN}/logout?redirect=${location?.href}`
    }
  }

  return {
    loginUrl,
    signUpUrl,
    logoutUrl
  }
}

export default useUrlAuth;
