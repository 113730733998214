import React from 'react';
import DayPicker, { DateUtils }  from 'react-day-picker';
import IconsImage from '../Images/Icons';
import { loaderStaticImage } from '../../utils/image';
import classnames from 'classnames';

const ChevronLeft = loaderStaticImage({src: 'static/images/icons/chevron-left.svg' });
const ChevronRigth = loaderStaticImage({src: 'static/images/icons/chevron-right.svg' });
const QuestionCircle = loaderStaticImage({src: '/static/images/icons/question-circle.svg' });
import {WEEKDAYS_SHORT, MONTHS, WEEKDAYS_LONG, FIRST_DAY_OF_WEEK, LABELS} from '../../utils/localization'

class DayPickerContainer extends React.PureComponent {
  constructor(props) {
    super(props);
    this.handleDayClick = this.handleDayClick.bind(this);
    this.switchLocale = this.switchLocale.bind(this);
    this.state = {
      locale: props.locale,
      selectedDay: null,
      tooltip: false
    };
  }

  handleDayClick(day, { selected }) {
    this.setState({
      selectedDay: selected ? undefined : day,
    });
  }

  switchLocale(e) {
    const locale = e.target.value || 'en';
    this.setState({ locale });
  }

  isDayDisabled = (day) => {
    return !this.props?.highlightDays?.some(disabledDay =>
      DateUtils.isSameDay(day, disabledDay)
    )
  }

  Navbar = ({
    nextMonth,
    previousMonth,
    onPreviousClick,
    onNextClick
  }) => {
    const { locale, t } = this.props;
    const months = MONTHS[locale];
    const prev = months[previousMonth.getMonth()];
    const next = months[nextMonth.getMonth()];

    const prevMonth = MONTHS[locale][new Date().getMonth() - 1];
    const otherMonth = MONTHS[locale][new Date().getMonth() + 2];
    const prevMonthButtonDisabled = prevMonth == prev;
    const nextMonthButtonDisabled = otherMonth == next;

    const styleLeft = {
      float: 'left',
    };
    const styleRight = {
      float: 'right',
    };

    
    return (
      <div className="react-datepicker">
        <div className="react-datepicker__header-info">
          <h4>
            {t('online_service.choose_date')}
            { this.props?.isSessionPackage && (
                <>
                  <span onMouseEnter={() => this.setState({tooltip: true})} onMouseLeave={() => this.setState({tooltip: false})}>
                    <IconsImage 
                      src={QuestionCircle} 
                      width={20} 
                      height={20} 
                      className="react-datepicker__header-info__icon"
                    />
                  </span>
                  <div className="sessionPackageInfo__tooltip">
                    <div className={classnames('tooltip-container', {
                      'show-tooltip': this.state.tooltip
                    })}>
                      <div className="tooltip">{t('online_service.choose_date_tooltip')}</div>
                    </div>
                  </div>
                </>
              )
            }
          </h4>

          <div className="react-datepicker__header-picker">
            <button
              style={styleLeft}
              className={`react-datepicker__header-button react-datepicker__header-button--previous ${
                prevMonthButtonDisabled ? 'disabled' : ''}`
              }
              disabled={prevMonthButtonDisabled || false}
              onClick={() => onPreviousClick()}>
              <IconsImage src={ChevronLeft} width={11} height={11} className="icon-chevron"/>
              {prev.slice(0, 3)}
            </button>
            <button
              style={styleRight}
              className={`react-datepicker__header-button react-datepicker__header-button--next ${
                nextMonthButtonDisabled ? 'disabled' : ''
                }`
              }
              disabled={nextMonthButtonDisabled || false}
              onClick={() => onNextClick()}>
              {next.slice(0, 3)}
              <IconsImage src={ChevronRigth} width={11} height={11} className="icon-chevron"/>
            </button>
          </div>
        </div>
      </div>
    );
  }


  render() {
    const { locale } = this.state;

    return (
      <div>
        <DayPicker
          locale={locale}
          months={MONTHS[locale]}
          onChange={(date) => renderDate(date)}
          showOutsideDays={false}
          weekdaysLong={WEEKDAYS_LONG[locale]}
          weekdaysShort={WEEKDAYS_SHORT[locale]}
          selectedDays={this.props.highlightDays}
          disabledDays={this.isDayDisabled}
          onDayClick={this.props.selectDate}
          firstDayOfWeek={FIRST_DAY_OF_WEEK[locale]}
          navbarElement={this.Navbar}
          labels={LABELS[locale]}
        />
      </div>
    );
  }
}

export default DayPickerContainer;
