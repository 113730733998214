import { ReactNode } from 'react'

import '@hotmart/cosmos/dist/modal/modal-footer'

export interface Props {
  children: ReactNode
}

const ModalFooter = ({ children }: Props) => (
  <hot-modal-footer>{children}</hot-modal-footer>
)

export default ModalFooter
