import { MODULES_CLUB } from '../../../constants';

export const mountProductModulesManual = (productSummary: ModulesItems) => {
  let productModules = [];

  productSummary.modules.forEach((module) => {
    productModules.push({
      name: module.title,
      pages: module.content
    });
  });

  return productModules;
};

export const mountProductModulesClub = (
  productSummaryClub: ModulesClubContent
) => {
  let productModules = [];

  if (productSummaryClub && productSummaryClub.modules?.length) {
    productModules = [...productSummaryClub.modules];
  }

  return productModules;
};

export const hasMoreContentToLoad = (profile: Profile): boolean => {
  return (
    profile?.productSummary?.modules.length > MODULES_CLUB.MAX_IMPORTED &&
    profile?.showModuleContentSummarized &&
    profile?.clubContentSummarized?.numberOfPages > 1
  );
};

export const getTotalModulesExtraPurchased = (modules: [ModulesItemsClub]) => {
  return modules?.length
    ? modules.filter((module) => module?.modulePurchase).length
    : 0;
};

export const calculeModulePosition = (
  position: number,
  indexModule: number,
  totalModulesPurchased: number
) => {
  const lastPosition = 9;
  let positionFirstIndex = position < lastPosition ? '0' : '';

  if (totalModulesPurchased) {
    positionFirstIndex = position <= lastPosition ? '0' : '';
  }

  return `${positionFirstIndex}${position + indexModule}`;
};
