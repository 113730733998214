import { ReactNode } from 'react';

import { useModal } from './hooks';

import '@hotmart/cosmos/dist/modal/modal-body';
import '@hotmart/cosmos/dist/modal/modal-header';

export interface Props {
  className?: string,
  children: ReactNode
}

const ModalBody = ({ className, children }: Props) => {
  const { closeModal } = useModal();

  return (
    <hot-modal-body class={className}>
      <hot-modal-header onClick={closeModal} />
      {children}
    </hot-modal-body>
  )
}

export default ModalBody;
