import React from 'react';
import { withTranslation } from 'next-i18next';
import classnames from 'classnames';
import { formatDateLocale, convertUTC } from '../../utils/date';

import IconsImage from '../Images/Icons';
import { loaderStaticImage } from '../../utils/image';
const dayjs = require('dayjs');

const Calendar = loaderStaticImage({
  src: 'static/images/icons/calendar-alt.svg'
});
const Clock = loaderStaticImage({ src: 'static/images/icons/clock.svg' });

type Props = {
  dataSheet: DataSheet;
  position?: string;
  showHour?: boolean;
  showDate?: boolean;
  dateFormat?: string;
  hourFormat?: string;
  t: (arg0: string, arg1?: {}) => string;
};

const EventSchedule = ({
  dataSheet,
  t,
  position = '',
  showHour = true,
  showDate = true,
  dateFormat = 'P',
  hourFormat = 'HH:mm'
}: Props) => {
  const { beginDateTime, endDateTime } = dataSheet;

  if (!beginDateTime && !endDateTime) {
    return null;
  }

  const eventStartDate = new Date(dataSheet?.beginDateTime);
  const eventFinalDate = new Date(dataSheet?.endDateTime);

  return (
    <div
      className={classnames('eventSchedule__container', {
        checkout: position === 'checkout'
      })}
    >
      {showDate && (
        <div className="eventSchedule__date">
          <IconsImage
            src={Calendar}
            width={17}
            height={17}
            className="eventSchedule__icons"
          />
          {eventStartDate && (
            <div className="event-date _ml-1">
              {formatDateLocale(convertUTC(beginDateTime), dateFormat)}
            </div>
          )}
          {eventStartDate && eventFinalDate && (
            <div className="_ml-1"> - </div>
          )}
          {eventFinalDate && (
            <div className="event-date _ml-1">
              {formatDateLocale(convertUTC(endDateTime), dateFormat)}
            </div>
          )}
        </div>
      )}
      {showHour && (
        <div
          className={classnames('_d-flex _align-items-center _mt-2', {
            '_ml-2': !position
          })}
        >
          <IconsImage
            src={Clock}
            width={17}
            height={17}
            className="eventSchedule__icons eventSchedule__clock"
          />
          <div className="event-time _ml-1">
            {t('online_event.hour', {
              hourBegin: dayjs(eventStartDate).format(hourFormat),
              hourEnd: dayjs(eventFinalDate).format(hourFormat)
            })}
          </div>
        </div>
      )}
    </div>
  );
};

export default withTranslation()(EventSchedule);
