import React, { useState, useEffect } from 'react';
import dynamic from 'next/dynamic';
import { ModulesProps } from './types';
import { useTranslation } from 'next-i18next';
import {
  mountProductModulesClub,
  mountProductModulesManual,
  hasMoreContentToLoad,
  calculeModulePosition,
  getTotalModulesExtraPurchased
} from './options';
import Service from 'src/@profile/service';
import classnames from 'classnames';
import { loaderStaticImage } from 'utils/image';

const AccordionTab = dynamic(() => import('components/Collapsible/Accordion'), {
  ssr: false
});

const ReadMore = dynamic(() => import('components/Button'), {
  ssr: false
});

const IconsImage = dynamic(() => import('components/Images/Icons'), {
  ssr: false
});

const ExtraContentIcon = loaderStaticImage({
  src: 'static/images/icons/extra_content.svg'
});

const Modules = (props: ModulesProps) => {
  const [productModules, setProductModules] = useState([]);
  const [readMore, setReadMore] = useState(false);
  const [loadingModules, setLoadingModules] = useState(false);
  const [totalModulesPurchased, setTotalModulesPurchased] = useState(0);
  const [page, setPage] = useState(1);
  const isRedesign = props.className === 'redesigned-modules';
  const { t } = useTranslation();
  let heightAccordionItems = !isRedesign ? 56 : 75;
  let indexModule = 1;

  useEffect(() => {
    if (props?.profile?.showModuleContentSummarized) {
      setProductModules(
        mountProductModulesClub(props.profile?.clubContentSummarized)
      );

      setTotalModulesPurchased(
        getTotalModulesExtraPurchased(
          props.profile?.clubContentSummarized?.modules
        )
      );
    } else if (props.profile?.productSummary) {
      setProductModules(
        mountProductModulesManual(props.profile?.productSummary)
      );
    }
  }, []);

  useEffect(() => {
    if (
      productModules?.length >=
      props.profile?.clubContentSummarized?.numberOfModules
    ) {
      setReadMore(true);
    } else {
      setReadMore(false);
    }
  }, [productModules]);

  const loadMoreContentFromClub = async () => {
    const productModulesOrder = productModules?.length - 1;
    const lastModuleOrder =
      productModulesOrder > 0 ? productModules[productModulesOrder]?.order : 1;
    const moreContentLoaded = await Service.getModulesClub(
      props.profile,
      lastModuleOrder
    );
    setLoadingModules(false);

    return moreContentLoaded?.modules?.length ? moreContentLoaded?.modules : [];
  };

  const toggleReadMore = async () => {
    if (readMore) {
      setProductModules(
        mountProductModulesClub(props.profile?.clubContentSummarized)
      );
      setPage(1);
    } else {
      setLoadingModules(true);
      const pagination = page + 1;
      const modulesLoaded = await loadMoreContentFromClub();

      if (modulesLoaded?.length) {
        const appendModules = [...productModules, ...modulesLoaded];
        setProductModules(appendModules);
      }

      setPage(pagination);
    }
  };

  return (
    productModules && (
      <>
        <div
          className={classnames(
            'accordion accordion__modules',
            props?.className
          )}
          style={{
            minHeight: productModules?.length
              ? `${
                  (productModules?.length - totalModulesPurchased) *
                  heightAccordionItems
                }px`
              : 'auto'
          }}
        >
          {productModules?.map((module, position) => {
            const isModuleExtraPurchased = module?.modulePurchase;

            if (isModuleExtraPurchased) {
              indexModule -= 1;
            }

            if (!isModuleExtraPurchased) {
              return (
                <AccordionTab
                  key={position}
                  index={position}
                  subtitle={calculeModulePosition(
                    position,
                    indexModule,
                    totalModulesPurchased
                  )}
                  title={`${module?.name}`}
                  sectionName={t('badge.digital_title')}
                  className={props.className}
                >
                  <ul className="accordion__description idented">
                    {module?.modulePurchase && (
                      <div className="accordion__extra__module _d-flex _align-items-baseline _align-items-md-center _px-4 _py-3 _mb-5 _bg-gray-100 _rounded-lg">
                        <IconsImage
                          className="accordion__extra__icon"
                          src={ExtraContentIcon}
                          width={15}
                          height={15}
                        />
                        <div className="_ml-2 _flex-1">{`${t(
                          'product.content_extra'
                        )}`}</div>
                      </div>
                    )}
                    {module?.pages?.length &&
                      module.pages.map((page, index) => {
                        return (
                          <li
                            className="accordion__text hover clickable"
                            key={index}
                            tabIndex={0}
                          >
                            {page?.title || page?.name}
                          </li>
                        );
                      })}
                  </ul>
                </AccordionTab>
              );
            }
          })}
        </div>
        {hasMoreContentToLoad(props?.profile) && (
          <ReadMore
            onClick={() => toggleReadMore()}
            readMore={readMore}
            className={
              !isRedesign
                ? 'button__read-more'
                : 'review-product__read-more _d-flex _align-items-center _justify-content-center'
            }
            complement={t('product.show_more_content')}
            label="show_content"
            variation="secondary"
            size="md"
            wrapperClassName={classnames({
              '_w-full _d-flex _justify-content-center _mt-6': isRedesign
            })}
            disabled={loadingModules}
            isRedesign={isRedesign}
          />
        )}
      </>
    )
  );
};

export default React.memo(Modules);
