import React from 'react';
import IconsImage from '../../Images/Icons';
import Button from 'components/Button/default';
import { loaderStaticImage } from 'utils/image';
import { sendAnalyticsEvent } from 'utils/datalayer';

const ArrowRightSolid = loaderStaticImage({
  src: 'static/images/icons/access_product.svg'
});

type Props = {
  t: (string) => void;
  link: string;
  salesEnabled: boolean;
  isMobile: boolean;
  environment: string;
  productId: number;
};

const AccessProduct = ({
  t,
  link,
  salesEnabled,
  environment,
  productId,
  isMobile
}: Props) => {
  const onAccessClick = () => {
    const cartStatus = salesEnabled ? 'OpenCart' : 'ClosedCart';
    const device = isMobile ? ':Mobile' : '';

    // GA Event
    sendAnalyticsEvent({
      event: 'interaction',
      event_category: `${environment}:Product`,
      event_action: `Click:AccessCourse:${cartStatus}${device}`,
      event_label: `Product:${productId}`
    });
  };

  if (link) {
    return (
      <a
        href={link}
        target="_new"
        className="sidebar__access"
        onClick={onAccessClick}
      >
        <>
          {t('product.access_product')}
          <Button
            className="sidebar__access-link"
            onClick={() => {}}
            variation="tertiary"
            size="sm"
          >
            {t('salesPage.access_your_product_now')}
            <IconsImage
              className="sidebar__access-arrow"
              src={ArrowRightSolid}
              width={12}
              height={12}
            />
          </Button>
        </>
      </a>
    );
  } else {
    return null;
  }
};

export default React.memo(AccessProduct);
