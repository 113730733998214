import { useContext, useState } from 'react';

import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { ProfileContext } from 'components/core/Provider/ProfileProvider';
import dynamic from 'next/dynamic';

import { getEnvironment } from 'utils/data';
import { loaderStaticImage } from 'utils/image';
import { sendAnalyticsEvent } from 'utils/datalayer';
import Utils from 'global/scripts/utils';
import IconsImage from 'components/Images/Icons';
import classNames from 'classnames';

const Dropdown = dynamic(() => import('components/Dropdown'), { ssr: false });
const DropdownButton = dynamic(() => import('components/Dropdown/Button'), { ssr: false });
const DropdownMenuItem = dynamic(() => import('components/Dropdown/MenuItem'), {
  ssr: false
});

const ShareIcon = loaderStaticImage({ src: 'static/images/icons/share.svg' });
const CopyIcon = loaderStaticImage({ src: 'static/images/icons/copy-black.svg' });
const WhatsappIcon = loaderStaticImage({ src: 'static/images/icons/social-media/whatsapp_black.svg' });

const onShareLink = (environment, id) => {
  sendAnalyticsEvent({
    event: 'interaction',
    event_category: `${environment}:Product`,
    event_action: 'Click:Share:Link',
    event_label: `Product:${id}`
  });
};

const onShareWhatsapp = (environment, id) => {
  sendAnalyticsEvent({
    event: 'interaction',
    event_category: `${environment}:Product`,
    event_action: 'Click:Share:Whatsapp',
    event_label: `Product:${id}`
  });
};

const ShareButton = ({ className, hideTextButton }: {className?: string, hideTextButton?: boolean}) => {
  const [showCopied, setShowCopied] = useState(false);
  const { t } = useTranslation();
  const { locale, query } = useRouter();
  const { profile } = useContext(ProfileContext);
  const url = Utils.mountUrlToShare(locale, query);
  const textWhatsapp = `${t('share.message')} ${url}`;
  const environment = getEnvironment(query?.sck);

  const onCopySuccess = () => {
    setShowCopied(true);
    setTimeout(() => {
      setShowCopied(false);
    }, 3000);
    onShareLink(environment, profile?.product?.productId);
  };

  return (
    <div>
      <Dropdown>
        <DropdownButton className={classNames("_d-flex _align-items-center _border-0", className)}>
          {!hideTextButton && (
            <span className="_pr-2 redesign-share-button-text _text-decoration-underline _line-height-lg">
              {`${t('share.button')}`}
            </span>
          )}
          <IconsImage src={ShareIcon} width={14} height={14} />
        </DropdownButton>
        <DropdownMenuItem>
          <a
            href={`https://wa.me/?&text=${textWhatsapp}`}
            onClick={() => onShareWhatsapp(environment, profile?.product?.productId)}
            aria-label={t('share.share_whatsapp')}
            target="_new"
            className="_text-gray-700"
          >
            <div className="_d-flex">
              <IconsImage src={WhatsappIcon} width={16} height={16} />
              <span className="_pl-4">
                {`${t('share.whatsapp')}`}
              </span>
            </div>
          </a>
        </DropdownMenuItem>
        <DropdownMenuItem>
          <a
            className="_d-flex"
            onClick={() => Utils.onCopy(url, onCopySuccess)}>
            <IconsImage src={CopyIcon} width={16} height={16} />
            <span className="_pl-4 _text-gray-700 _text-decoration-underline">
              {`${t('share.copy')}`}
            </span>
          </a>
        </DropdownMenuItem>
      </Dropdown>
    </div>
  )
}

export default ShareButton;
