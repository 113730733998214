import Request from '@hotmart/request';
import { Constants } from './request';

class LeadService {
  async sendLead(data) {
    try {
      return Request(Constants.SEND_LEAD_CAPTURE, { ...data });
    } catch (e) {
      console.log(e);
    }
  }
  async sendLeadLaunch(data) {
    try {
      return Request(Constants.SEND_LEAD_LAUNCH, { ...data });
    } catch (e) {
      console.log(e);
    }
  }
}

export default new LeadService();
