import { axios } from '@hotmart/request';
import config from '../../../env.config';
import dynamic from 'next/dynamic';

async function GetVideoImage(code: string, productImage: string) {
  let response;

  try {
    response = await axios.get(
      `${config.envConfig.APP}${
        config.currentEnv !== 'development' ? '/product' : ''
      }/api/video/vimeo?url=${code}`
    );
  } catch (e) {
    console.error(e);
  }

  if (response?.data?.error || !response?.data) {
    return {
      thumbnail_url: productImage,
      mountPlay: true
    };
  }

  if (response?.data && !response?.data?.thumbnail_url) {
    response.data.thumbnail_url = productImage;
    response.data.mountPlay = true;
  }

  return response?.data;
}

export const getVimeoEmbed = async (
  code: string,
  onVideoError: (arg1: boolean, arg2: Error) => void,
  showVideoEmbed: boolean,
  setShowVideoEmbed: React.Dispatch<React.SetStateAction<boolean>>,
  setVideoFrame: React.Dispatch<React.SetStateAction<JSX.Element | string>>,
  productImage: string
) => {
  // @ts-ignore
  const Vimeo = dynamic(() => import('@u-wave/react-vimeo'), { ssr: false });

  const insertVimeoVideoOnClick = (code) => {
    return (
      <div className={`videoWrapper__vimeo-video`} id="iframeWrapper">
        <Vimeo
          video={code}
          className="video"
          onError={(message) => onVideoError(false, message)}
          autoplay
        />
      </div>
    );
  };

  const clickShowVideo = () => {
    setVideoFrame('');
    setShowVideoEmbed(true);
  };

  if (showVideoEmbed) {
    return insertVimeoVideoOnClick(code);
  } else {
    const thumb = await GetVideoImage(code, productImage);

    if (thumb) {
      return {
        component: (
          <div className={`videoWrapper__vimeo`} id="iframeWrapper">
            <link rel="preload" href={thumb?.thumbnail_url} as="image" />
            <div
              style={{ backgroundImage: `url(${thumb?.thumbnail_url})` }}
              data-title="Video principal"
              className="videoWrapper__vimeo-thumb"
              onKeyPress={() => clickShowVideo()}
              onClick={() => clickShowVideo()}
            >
              <div>
                <button
                  className="videoWrapper__vimeo-play"
                  aria-label="Play Video"
                  onClick={clickShowVideo}
                ></button>
              </div>
            </div>
          </div>
        ),
        thumb
      };
    }
  }
};
