import React from 'react';


const PercentageBar = ({ percentage }) => {
  return (
    <div className="percentage-bar">
      <div
        className="percentage-bar-progress"
        style={{ width: `${percentage}%` }}
      />
    </div>
  );
};

export default PercentageBar;
