import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'next-i18next';

import { cardTypes } from '../../constants';

import MiniProductCard from './Mini';
import ProductCard from './Product';

export const Card = (props) => {
  const { product, onClick, isMobile, sectionTitle, className } = props;

  if (isMobile) {
    return (
      <MiniProductCard
        product={product}
        onClick={onClick}
        sectionTitle={sectionTitle}
      />
    );
  } else {
    return (
      <ProductCard
        product={product}
        onClick={onClick}
        sectionTitle={sectionTitle}
        className={className}
      />
    );
  }
};

Card.propTypes = {
  image: PropTypes.string,
  description: PropTypes.string,
  tag: PropTypes.string,
  author: PropTypes.string,
  title: PropTypes.string,
  type: PropTypes.string,
  onClick: PropTypes.func
};

Card.defaultProps = {
  type: cardTypes.DEFAULT,
  tag: '',
  onClick: () => {}
};

export default withTranslation()(Card);
