import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Tab from './Tab';
import { sendAnalyticsEvent } from '../../utils/datalayer';
import '@cosmos/nav/nav.css';

const Tabs = (props) => {
  const filteredChildren = props.children.filter(
    (item) => item && typeof item !== 'undefined'
  );
  const [activeTab, setActiveTab] = useState(
    filteredChildren[0]?.props['data-tab']
  );

  const onClickTabItem = (tab, name) => {
    setActiveTab(tab);

    if (name) {
      sendAnalyticsEvent({
        event: 'interaction',
        event_category: 'Product:Product',
        event_action: `Click:${name}`,
        event_label: name
      });
    }
  };

  useEffect(() => {
    const filteredChildren = props.children.filter(
      (item) => item && typeof item !== 'undefined'
    );

    setActiveTab(filteredChildren[0]?.props['data-tab'])
  }, [props.children]);

  return (
    <div>
      <hot-nav className="hot-nav--tabs">
        <ul className={classnames('tab-content__list')}>
          {filteredChildren.map((child, index) => {
            if (child) {
              const label = child?.props['data-label'];
              const dataName = child?.props['data-tab'];
              const name = child?.props['data-name'];
              const isTabActive = activeTab === dataName;

              return (
                <Tab
                  isTabActive={isTabActive}
                  dataName={dataName}
                  key={`${label}-${index}`}
                  label={label}
                  name={name}
                  onClick={onClickTabItem}
                  onKeyPress={onClickTabItem}
                  aria-selected={activeTab}
                  tabIndex={0}
                  role="tab"
                  id={`tab-${index}`}
                  aria-controls={`panel-${index}`}
                />
              );
            }
          })}
        </ul>
      </hot-nav>
      <div className="tab-content">
        {filteredChildren.map((child, index) => {
          if (child) {
            return (
              <div
                key={`${child.props['data-label']}-${index}`}
                id={`panel-${index}`}
                className={`content ${
                  child.props['data-tab'] !== activeTab ? 'hidden' : 'active'
                }`}
              >
                {child ? child.props.children : ''}
              </div>
            );
          }
        })}
      </div>
    </div>
  );
};

Tabs.propTypes = {
  children: PropTypes.instanceOf(Array).isRequired
};

export default Tabs;
