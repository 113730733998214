import Cookie from 'js-cookie'

export const getSessionId = (ip = null) => {
  return Cookie.get('_ga') || Cookie.get('sid') || Cookie.get('hotid') || ip;
}

export function getRemoteAddr(ip) {
  let ipAddress = ip;

  if (ipAddress?.match(/,/)) {
    ipAddress = ipAddress.split(',')[0];
  }

  if (ip.match(/ff/)) {
    ipAddress = "";
  }

  if (ipAddress?.match(/::1/) || ipAddress?.match(/::ffff/)) {
    ipAddress = '127.0.0.1'
  }

  return ipAddress;
}
