import React, { Fragment, useEffect, useState } from 'react';
import ReadMore from 'components/Button';
import ReviewsRows from './ReviewsRows';
import { getReviewPercentage } from './useReview';
import Service from '../../@profile/service';
import Filter from './Filter';
import { ReviewsProps } from './types';

const Reviews = ({
  t,
  profile,
  query,
  totalRating,
  setRatingData,
  isRedesign
}: ReviewsProps) => {
  const [page, setPage] = useState(1);
  const [reviewsData, setReviewsData] = useState([]);
  const [loadReview, setLoadReview] = useState(false);
  const [filterSelected, setFilterSelected] = useState('ALL');
  const [loadMore, setLoadMore] = useState(true);
  const [readMore, setReadMore] = useState(false);
  const totalPerPage = 5;
  const reviewTitle = t('reviews.title');
  const { customerReview, product, showReviewsOnProductProfile } = profile;
  const { language, productId } = product;
  const sortAnswers = true;

  const getReviews = async () => {
    try {
      const reviews = await Service.getProductReviewsAnswers(
        productId,
        totalPerPage,
        page,
        filterSelected,
        sortAnswers
      );

      if (reviews && reviews.answers) {
        const reviewsObj = [];
        reviews.answers.map((review) => {
          const reviewObj = {
            ucode: review.ucode,
            date: review.date,
            userName: review.userName,
            userAvatar: review.userAvatar,
            answers: {}
          };

          review.answers.map((answer) => {
            reviewObj.answers[answer.type] = answer.answer;
          });

          setReviewsData([...reviewsData, reviewObj]);

          reviewsObj.push(reviewObj);
        });

        if (reviewsObj?.length > 0) {
          setLoadReview(false);

          if (loadMore) {
            setReviewsData([...reviewsData, ...reviewsObj]);
          } else {
            setReviewsData(reviewsObj);
          }
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  const toggleReadMore = () => {
    if (readMore) {
      const firstReviews = reviewsData.splice(0, totalPerPage);
      setReviewsData(firstReviews);
      setPage(1);
    } else {
      setPage(page + 1);
      setLoadReview(true);
    }

    setLoadMore(true);
  };

  const setFilter = (filter) => {
    setFilterSelected(filter);
    setPage(1);
    setLoadMore(false);
  };

  useEffect(() => {
    if (page && filterSelected) {
      getReviews();
    }
  }, [page, filterSelected]);

  useEffect(() => {
    if (reviewsData?.length >= customerReview?.totalReviews) {
      setReadMore(true);
    } else {
      setReadMore(false);
    }
  }, [reviewsData]);

  useEffect(() => {
    getReviewPercentage(productId, query?.sck, setRatingData);
  }, []);

  return (
    <div className="reviews-wrapper">
      <div className="reviews-header">
        <Filter
          reviews={reviewsData}
          sort={filterSelected}
          setFilter={setFilter}
          t={t}
          showNewLabel={isRedesign}
        />
      </div>

      {totalRating > 0 && customerReview?.totalReviews > 0 && (
        <Fragment>
          {reviewsData.length > 0 &&
            reviewsData.map((review, index) => {
              return (
                <ReviewsRows
                  rating={review.answers.RATING_1_5}
                  date={review.date}
                  language={language}
                  showReviewsOnProductProfile={showReviewsOnProductProfile}
                  review={review.answers.LONG_ANSWER}
                  userName={review.userName}
                  userAvatar={review.userAvatar}
                  clickable
                  key={review.ucode}
                />
              );
            })}
          {reviewsData.length > 0 &&
            customerReview?.totalReviews >= totalPerPage && (
              <ReadMore
                onClick={() => toggleReadMore()}
                readMore={readMore}
                className="review-product__read-more _d-flex _align-items-center"
                label="read"
                complement={reviewTitle}
                disabled={loadReview}
                variation="secondary"
                size="md"
                isRedesign={isRedesign}
              />
            )}
        </Fragment>
      )}
    </div>
  );
};

export default React.memo(Reviews);
