import { sendAnalyticsEvent } from 'utils/datalayer';
import DatahubService from 'utils/datahub';
import { EVENT_ACTIONS } from 'utils/datahub.constants';
import { getSessionId } from 'utils/cookies';
import { productQueryString, sourceParams } from '../constants';

export const sendProductDetailView = (
  product,
  offerDetail,
  checkoutAvailable = 'Yes'
) => {
  if (!product) {
    return;
  }
  const { productId, name, category } = product;
  const { offerPrice } = offerDetail || {};
  sendAnalyticsEvent({
    event: 'ecommerce',
    event_category: 'Enhanced Ecommerce',
    event_action: 'Product Detail View',
    event_label: '',
    checkoutAvailable,
    ecommerce: {
      currencyCode: `${offerPrice?.currencyCode || ''}`,
      detail: {
        actionField: { list: `${category || ''}` },
        products: [
          {
            name: `${name || ''}`,
            id: `${productId || ''}`,
            price: `${offerPrice?.value || '0'}`,
            category: `${category || ''}`
          }
        ]
      }
    }
  });
};

const getOriginParam = (sck: string) => {
  if (sck === productQueryString.PRODUCTS_RECOMMENDED[0].value) {
    return sourceParams.PRODUCTS_RECOMMENDED;
  }

  if (sck === productQueryString.PRODUCTS_HUB[0].value) {
    return sourceParams.PRODUCTS_HUB;
  }

  return '';
};

export const buyButtonOnClick = (context: TypeContext, ip: string) => {
  const { name, productId, category } = context?.profile?.product || {};
  let eventLabel = '';

  if (context?.query?.origin) {
    const previousEventLabel = eventLabel ? `${eventLabel}+` : '';

    if (context?.query?.origin === sourceParams.CREATOR_MODAL) {
      eventLabel = `${previousEventLabel}${sourceParams.CREATOR_MODAL}`;
    }

    if (context?.query?.origin === sourceParams.CREATOR_PAGE) {
      eventLabel = `${previousEventLabel}${sourceParams.CREATOR_PAGE}`;
    }
  }

  const offerPrice =
    context?.checkout?.data?.offer?.paymentInfoResponse?.offerPrice || {};
  sendAnalyticsEvent({
    event: 'ecommerce',
    event_category: 'Enhanced Ecommerce',
    event_action: 'Add to cart',
    event_label: eventLabel,
    ecommerce: {
      currencyCode: `${offerPrice.currencyCode || ''}`,
      add: {
        products: [
          {
            name: `${name || ''}`,
            id: `${productId || ''}`,
            price: `${offerPrice.value || '0'}`,
            category: `${category || ''}`
          }
        ]
      }
    }
  });

  const paramOrigin = getOriginParam(context?.query?.sck);

  DatahubService.sendEvent({
    system: 'display',
    action: EVENT_ACTIONS.BUY,
    sessionId: getSessionId(ip),
    productId: productId || '',
    ...(paramOrigin ? { origin: paramOrigin } : {}),
    searchId: context?.query?.search || ''
  });
};
