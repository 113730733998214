import { useState, useEffect } from 'react';
import { useTranslation, i18n } from 'next-i18next';
import Utils from '../../../global/scripts/utils';
import { categories, urls } from '../../../constants';
import {
  FaqComponentProps,
  FaqItemsProps,
  FaqProps,
  ProductInfoProps
} from './types';
import { includeSignupInList } from '../../../utils/includeSignup';

const typeProduct = (product: ProductInfo, environment: string) => {
  const { t } = useTranslation();

  const linkAccessProducts = mountLinkSteps(
    environment,
    t('faq.product_access.description', { product: product?.name }),
    t('faq.product_access.steps'),
    t('faq.product_access.purchases')
  );
  const linkAccessEvents = mountLinkSteps(
    environment,
    t('faq.event.description'),
    t('faq.product_access.steps'),
    t('faq.event.purchases')
  );

  switch (product?.format) {
    case categories.COURSES_ONLINE:
      return {
        isCourse: true,
        isEvent: false,
        isServiceOnline: false,
        isProduct: false,
        label: t('faq.type_course'),
        titleAccessProduct: t('faq.product_access.title'),
        contentAccessProduct: linkAccessProducts,
        wishListContent: t('faq.wish_content', {
          type: t('faq.type_course'),
          typePlural: t('faq.type_courses')
        })
      };

    case categories.EVENT_ONLINE:
    case categories.EVENT_PRESENTIAL:
      return {
        isCourse: false,
        isEvent: true,
        isServiceOnline: false,
        isProduct: false,
        label: t('faq.type_event'),
        titleAccessProduct: t('faq.event.title'),
        contentAccessProduct: linkAccessEvents,
        wishListContent: t('faq.wish_content', {
          type: t('faq.type_event'),
          typePlural: t('faq.type_events')
        })
      };

    case categories.SERVICE_ONLINE:
      return {
        isCourse: false,
        isEvent: false,
        isServiceOnline: true,
        isProduct: false,
        label: t('faq.type_service'),
        titleAccessProduct: t('faq.product_access.title'),
        contentAccessProduct: linkAccessProducts,
        wishListContent: t('faq.service_online')
      };

    default:
      return {
        isCourse: false,
        isEvent: false,
        isServiceOnline: false,
        isProduct: true,
        label: t('faq.type_product'),
        titleAccessProduct: t('faq.product_access.title'),
        contentAccessProduct: linkAccessProducts,
        wishListContent: `${t('faq.product_general')}${t('faq.wish_content', {
          type: t('faq.type_product'),
          typePlural: t('faq.type_products')
        })}`
      };
  }
};

const mountLinkSteps = (
  environment: string,
  title: string,
  description: string,
  info: string
) => {
  const { t } = useTranslation();
  const [linkRedirect, setLinkRedirect] = useState('');

  useEffect(() => {
    const domain = `${window.location.host}${window.location.pathname}${window.location.search}`;
    const urlLogin = urls.LOGIN.replace('returnURL', domain);

    setLinkRedirect(urlLogin || '#');
  }, []);

  const stepLogin = `<div>${t('faq.event.first_step')}
    <a href="${linkRedirect}"
      alt="${t('product_access.login.link')}"
      rel="noreferrer"
      data-gtm-event="True"
      data-event-category="${environment}:Product"
      data-event-action="Click:AccessCourse:FAQ"
      data-event-label="Login">
    ${t('product_access.login.link')}
    </a>
  </div>`;
  const stepAccount = `<div>${t('faq.event.second_step')}
    <a
      href="${linkRedirect}"
      data-gtm-event="True"
      data-event-category="${environment}:Product"
      data-event-action="Click:AccessCourse:FAQ"
      data-event-label="MyAccount">
      ${t('product_access.account.link')}
    </a>
  </div>`;
  const stepPurchases = `<div>${t('faq.event.third_step')}
    <a
      href="${urls.PRODUCTS}"
      rel="noreferrer"
      alt="${t('product_access.purchase.link')}"
      data-gtm-event="True"
      data-event-category="${environment}:Product"
      data-event-action="Click:AccessCourse:FAQ"
      data-event-label="MyPurchases">
      ${t('product_access.purchase.link')}
    </a>
  </div>`;

  return `${title}${description}${stepLogin}${stepAccount}${stepPurchases}${info}`;
};

const mountItems = (
  props: FaqProps,
  productInfo: ProductInfoProps
): FaqComponentProps => {
  const { t } = useTranslation();
  const finalRating = props?.rating || 0;
  const items = [{ title: '', content: '', uuid: '' }] as [FaqItemsProps];
  let mainEntity = [];

  if (!productInfo.isServiceOnline && !productInfo.isEvent) {
    items.push({
      title: t('faq.review_title', { product: props?.profile?.product?.name }),
      content: t('faq.review_content', { type: productInfo.label }),
      uuid: Utils.generateUUID()
    });

    {
      props?.showReviews &&
        finalRating > 0 &&
        items.push({
          title: t('faq.stars_title', { type: productInfo.label }),
          content: t('faq.stars_content', {
            rating: finalRating,
            product: props?.profile?.product?.name,
            type: productInfo.label
          }),
          uuid: Utils.generateUUID()
        });
    }
  }

  items.push({
    title: t('faq.assurance_title'),
    content: t('faq.assurance_content'),
    uuid: Utils.generateUUID()
  });

  if (productInfo.isEvent || productInfo.isCourse) {
    const productAccess = productInfo.isEvent
      ? t('faq.certificate.event')
      : t('faq.certificate.course');
    items.push({
      title: t('faq.certificate.title'),
      content: `${productAccess}${t('faq.certificate.description')}`,
      uuid: Utils.generateUUID()
    });
  }

  items.push({
    title: t('faq.differentials_title'),
    content: t('faq.differentials_description'),
    uuid: Utils.generateUUID()
  });
  items.push({
    title: productInfo.titleAccessProduct,
    content: productInfo.contentAccessProduct,
    uuid: Utils.generateUUID()
  });
  items.push({
    title: t('faq.wish_title', { type: productInfo.label }),
    content: productInfo.wishListContent,
    uuid: Utils.generateUUID()
  });
  items.push({
    title: t('faq.affiliate_title'),
    content: t('faq.affiliate_description'),
    uuid: Utils.generateUUID()
  });
  items.push({
    title: t('faq.alert_title'),
    content: t('faq.alert_description'),
    uuid: Utils.generateUUID()
  });
  items.push({
    title: t('faq.sac_title'),
    content: t('faq.sac_content'),
    uuid: Utils.generateUUID(),
    lastItem: true
  });

  // Organic Squad
  if (i18n?.language === 'pt-br') {
    const signUpQuestion = {
      title: 'Como faço pra criar um produto?',
      test: true,
      content:
        "Primeiro, você precisa criar seu cadastro grátis, <a href='https://sso.hotmart.com/signup?service=https://hotmart.com/auth/signup-callback&systemOrigin=app-hotmart-auth&client_id=f2d997e6-876e-431e-9c0d-c361b8551763' target='_blank'>clicando aqui</a>. Lá dentro, você tem acesso a mais de 15 formatos pra transformar o que você sabe em um produto digital. Além disso, vai poder acessar também o Hotmart Academy.",
      uuid: Utils.generateUUID()
    };

    includeSignupInList(items, signUpQuestion, 3);
  }

  return { items, mainEntity };
};

export const faqItems = ({ ...props }: FaqProps) => {
  const productInfo = typeProduct(props?.profile?.product, props?.environment);
  return mountItems(props, productInfo);
};
