import React, { useContext, useState, useEffect } from 'react';

import LazyHydrate from 'react-lazy-hydration';

import { StoreContext } from 'components/core/Provider/Store';
import { withTranslation } from 'next-i18next';
import Link from 'next/link';
import { loadSearchData } from '../../utils/search';
import { currentEnv } from '../../../env.config';
import { sendAnalyticsEvent } from '../../utils/datalayer';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { useInView } from 'react-intersection-observer';
import DatahubService from '../../utils/datahub';
import { EVENT_ACTIONS, SEARCH_SOURCES } from '../../utils/datahub.constants';
import { getSessionId } from '../../utils/cookies';
import { getTopificationRouter, mountMarketplacePage } from 'utils/url';
import { subsPerTopic } from '../../constants';

const Header = dynamic(() => import('components/Header'), { ssr: false });
const Footer = dynamic(() => import('components/Footer'), { ssr: false });

const Body = ({
  children,
  language,
  profile,
  showSearch,
  showFooter,
  showHeader,
  t,
  headerRef,
  project,
  simplified,
  ip,
  isMobile
}) => {
  const store = useContext(StoreContext);
  const { belongsToMarketplace } = profile;
  const searchEnable = showSearch && belongsToMarketplace;
  const searchData = loadSearchData(t, language);

  const { asPath } = useRouter();
  const [footerView, setFooterView] = useInView({
    threshold: 0,
    triggerOnce: true
  });

  const focusComponentId = (element) => {
    const el = document.getElementById(element);

    if (el) {
      el.focus();
      el?.scrollIntoView();
    }
  };

  const focusComponentClass = (element) => {
    const el = document.getElementsByClassName(element)[0];

    if (el) {
      el.focus();
    }
  };

  const getUrl = (url) => {
    const splitedUrl = url?.split('#');
    return splitedUrl[0];
  };

  return (
    <div className="body-redesign">
      <div className="shortcuts">
        <Link
          href={`${getUrl(asPath)}#text_description`}
          scroll={true}
          replace={true}
        >
          <a
            className="sr-only sr-only-focusable"
            tabIndex={0}
            accessKey="1"
            data-testid="main-content"
            onClick={() => focusComponentId('read-more-description')}
          >
            <span>{t('alt_texts.main_content')}</span>
          </a>
        </Link>
        <Link href={`${getUrl(asPath)}#payment`} scroll={true} replace={true}>
          <a
            className="sr-only sr-only-focusable"
            tabIndex={0}
            accessKey="2"
            data-testid="checkout-content"
            onClick={() => focusComponentClass('btn-buy-sidebar')}
          >
            <span>{t('alt_texts.main_payment')}</span>
          </a>
        </Link>
        <Link
          href={`${getUrl(asPath)}#hm-mktplace-footer`}
          scroll={true}
          replace={true}
        >
          <a
            className="sr-only sr-only-focusable"
            tabIndex={0}
            accessKey="3"
            data-testid="language-content"
            onClick={() => focusComponentClass('languageSelected')}
          >
            <span>{t('alt_texts.main_footer')}</span>
          </a>
        </Link>
      </div>
      <LazyHydrate whenIdle>
        <Header
          store={store}
          lng={language}
          isMarketplace={searchEnable} // show marketplace logo
          showSearch={searchEnable}
          showCategoryMenu={searchEnable}
          homeURL={mountMarketplacePage(language)}
          ip={ip}
          isMobile={isMobile}
          searchSuggestions={searchData.suggestions}
          showHeader={showHeader}
          headerRef={headerRef}
          t={t}
          project={project}
          environment={`${currentEnv || ''}`}
          registerLabel={t('general.signup')}
          accessButtonsDirection="vertical"
          showHeaderSignInSignUp
          onSuggestionClick={async (suggestion) => {
            sendAnalyticsEvent({
              event: 'interaction',
              event_category: `${project || ''}:Search:Header`,
              event_action: 'Click:Suggestions',
              event_label: `${(suggestion && suggestion.key) || ''}`
            });

            DatahubService.sendEvent({
              action: EVENT_ACTIONS.SEARCH,
              locale: language,
              term: `${(suggestion && suggestion.value) || ''}`,
              ip: ip,
              sessionId: getSessionId(),
              source: SEARCH_SOURCES.TAG,
              path: window.location.href
            });
          }}
          onAutoCompleteClick={async (value) => {
            DatahubService.sendEvent({
              action: EVENT_ACTIONS.SEARCH,
              locale: language,
              term: `${(value && value.title) || ''}`,
              ip: ip,
              sessionId: getSessionId(),
              source: SEARCH_SOURCES.HEADER,
              path: window.location.href
            });
          }}
        />
      </LazyHydrate>
      {children}
      <div ref={footerView}>
        <Footer
          language={language}
          user={store?.user}
          showFooter={showFooter && searchEnable}
          project={project}
          isMarketplace={searchEnable}
          simplified={simplified}
          showFooterView={setFooterView}
        >
          {!isMobile && (
            <>
              <h3 className="content-collapsed--title _text-gray-900 _text-5 _font-weight-bold _pb-4 _mb-6 _mb-lg-9 _border-bottom">
                {t('topification_page.topifications_title')}
              </h3>
              <ul className="topification__list topification__list--container">
                {subsPerTopic &&
                  Object.entries(subsPerTopic).map(
                    ([topification, subcategoryList]) => {
                      const topificationUrl = getTopificationRouter({
                        language: language,
                        topification
                      });

                      return (
                        <li className="_my-1 _pb-6 _mb-5" key={topification}>
                          <Link href={topificationUrl}>
                            <a className="_text-gray-900 _text-2 _text-decoration-none _font-weight-bold">
                              {t(`topification.${topification}`)}
                            </a>
                          </Link>
                          <ul className="topification__list">
                            {subcategoryList.map((topic, index) => {
                              const topicUrl = getTopificationRouter({
                                language: language,
                                topification,
                                topic
                              });

                              return (
                                <li
                                  className="_my-2 topification__list-item"
                                  key={`${topic}-${index}`}
                                >
                                  <Link href={topicUrl}>
                                    <a className="_text-gray-900 _text-2 _text-decoration-none">
                                      {t(`topic.${topic}`)}
                                    </a>
                                  </Link>
                                </li>
                              );
                            })}
                          </ul>
                        </li>
                      );
                    }
                  )}
              </ul>
            </>
          )}
        </Footer>
      </div>
    </div>
  );
};

Body.defaultProps = {
  showSearch: true,
  showFooter: true,
  showHeader: true,
  language: 'en'
};

export default withTranslation()(React.memo(Body));
