import Service from '../../@profile/service';
import { sendAnalyticsEvent } from 'utils/datalayer';

const getProductReviews = async (id: Number) => {
  try {
    const productReviews = await Service.getProductReviews(id);
    return productReviews;
  } catch (err) {
    return false;
  }
};

const getReviewPercentage = (
  productId: Number,
  sck: string,
  setRatingData: (ratingObj: Object) => void
) => {
  getProductReviews(productId)
    .then((result) => {
      if (result) {
        sendAnalyticsEvent({
          event: 'noninteraction',
          event_category:
            sck && sck === 'HOTMART_SITE'
              ? 'Marketplace:Product'
              : 'Product:Product',
          event_action: 'Show:Evalution',
          event_label: `${result?.average || 'Not Available'}`
        });

        const ratingObj = {
          average: result.average || 0,
          totalAnswers: result.totalAnswers || 0,
          ratingCount: { 5: 0, 4: 0, 3: 0, 2: 0, 1: 0 },
          ratingPercentage: { 5: 0, 4: 0, 3: 0, 2: 0, 1: 0 },
          latestAnswers: [],
          questionsEvaluation: {},
          totalPercent: result?.ratingPercent || 0,
          bestSeller: result?.bestSeller || false
        };

        if (result.ratingCount) {
          result.ratingCount.map((item) => {
            ratingObj.ratingCount[item.ratingValue] = item.count;
            ratingObj.ratingPercentage[item.ratingValue] =
              Math.round((item.count / result.totalAnswers) * 100 * 100) / 100;
          });

          const latestAnswers = [];
          const answers = result.latestAnswers || [];
          answers.map((review) => {
            const reviewObj = {
              ucode: review.ucode,
              date: review.date,
              userName: review.userName,
              userAvatar: review.userAvatar,
              answers: {}
            };
            review.answers.map((answer) => {
              reviewObj.answers[answer.type] = answer.answer;
            });
            latestAnswers.push(reviewObj);
          });
          ratingObj.latestAnswers = latestAnswers;

          const questionsEvaluation = {};
          if (result.questionsEvaluation) {
            result.questionsEvaluation.map((question) => {
              if (!questionsEvaluation[question.language]) {
                questionsEvaluation[question.language] = [];
              }

              questionsEvaluation[question.language].push({ ...question });
            });
          }

          ratingObj.questionsEvaluation = questionsEvaluation;
          setRatingData(ratingObj);
        }
      }
    })
    .catch((e) => {
      console.error(e);
    });
};

export { getReviewPercentage };
