import React from 'react';

type Props = {
  t: (string) => void;
  format: string;
  category: string;
};

const ProductCategory = ({ t, format, category }: Props) => {
  const tagTranslated = category?.toString().match('category')
    ? t(category)
    : t(`subcategory.${category}`);

  return (
    <div className="sidebar__options">
      <div>
        <span className="sidebar__title">
          {`${t('product.category')}`}
        </span>
        {`: ${tagTranslated}`}
      </div>
      <div>
        <span className="sidebar__title">{`${t('product.format')}`}</span> 
        {`: ${t(format)}`}
      </div>
    </div>
  );
};

export default React.memo(ProductCategory);
