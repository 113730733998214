import React, { Fragment, useContext, useEffect, useState } from 'react';
import dynamic from 'next/dynamic';
import RatingGrid from './RatingGrid';
import RatingMainBox from 'components/RatingMainBox';
import { RatingData, ReviewsContainerProps } from './types';
import { ProfileContext } from '../core/Provider/ProfileProvider';

const Reviews = dynamic(() => import('components/Reviews'));

const ReviewsContainer = ({
  t,
  showReviews,
  totalRating,
  profile,
  query,
  isRedesign
}: ReviewsContainerProps) => {
  const context = useContext(ProfileContext);

  const percentageObj = {
    1: 0,
    2: 0,
    3: 0,
    4: 0,
    5: 0
  };
  const { customerReview } = profile;
  const [ratingData, setRatingData] = useState<RatingData>({
    average: 0,
    bestSeller: false,
    latestAnswers: [],
    questionsEvaluation: [],
    ratingCount: percentageObj,
    ratingPercentage: percentageObj,
    totalAnswers: 0,
    totalPercent: 0
  });

  useEffect(() => {
    if (ratingData?.average) {
      context.changeState('profile', {
        ...profile,
        reviews: { ...ratingData }
      });
    }
  }, [ratingData]);

  return (
    <div className="reviews-wrapper">
      <div className="reviews-header">
        <h2>{t('reviews.title')}</h2>
      </div>
      {totalRating > 0 && customerReview?.totalReviews > 0 && (
        <Fragment>
          <div className="row rating-box">
            <div className="grades">
              <RatingMainBox
                averageRating={totalRating}
                totalAnswers={customerReview.totalReviews}
              />
            </div>
            <div className="stars-details">
              <RatingGrid percentageObj={{ ...ratingData?.ratingPercentage }} />
            </div>
          </div>
          <div>
            {showReviews && (
              <Reviews
                query={query}
                t={t}
                profile={profile}
                totalRating={totalRating}
                setRatingData={setRatingData}
                isRedesign={isRedesign}
              />
            )}
          </div>
        </Fragment>
      )}
    </div>
  );
};

export default React.memo(ReviewsContainer);
