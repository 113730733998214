import CampaignService from '../@campaign/service';
import { campaignScreens, campaignStatus, categories, seasonInfo } from '../constants';

export const getSectionCampaign = (campaign, name, section) => {
  return Object.keys(campaign || [])
    .map((item) => {
      if (!section) {
        return campaign[item].screen;
      } else if (campaign[item].name === section) {
        return campaign[item].screen;
      }
    })
    .indexOf(name);
};

export const extractDetailsCampaign = (
  campaign: any,
  name: string,
  isMobile: boolean,
  section: string | boolean
) => {
  const sectionData = getSectionCampaign(campaign, name, section || false);
  const mobile = 'MOBILE';
  const sections = {};

  if (sectionData > -1) {
    const components = campaign[sectionData]?.components;
    const hasMobile = components
      ? Object.keys(components).some(
        (item) => components[item].deviceType === mobile
      )
      : false;
    const typeDevice = isMobile && hasMobile ? mobile : 'WEB';

    if (components) {
      Object.keys(components).forEach((item) => {
        if (components[item].deviceType === typeDevice) {
          sections[components[item].name.toLowerCase()] =
            components[item].attributes;
        }
      });
    }
  }

  return sections;
};

export const hasActiveCampaign = (campaign) => {
  return (campaign?.status || '').toLowerCase() === campaignStatus.RUNNING;
};

export function counterBlackNovember() {
  const today = new Date();
  const blackNovemberDate = new Date(seasonInfo[0]?.END_DATE);

  var diff = Math.abs(today.getTime() - blackNovemberDate.getTime());
  return Math.ceil(diff / (1000 * 3600 * 24));
}

export const getDataCampaign = async (campaign) => {
  let setupCampaign = {};

  try {
    setupCampaign = await CampaignService.getInfoCampaign({ slug: campaign });
    return setupCampaign;
  } catch (err) {
    console.error(err);
  }

  return setupCampaign;
};

export const getCampaignData = (campaign, campaignSections) => {
  const hasActiveCampaign =
    (campaign?.status || '').toLowerCase() === campaignStatus.RUNNING;
  const campaignData = hasActiveCampaign
    ? extractDetailsCampaign(
      campaign?.sections,
      campaignScreens.PRODUCT_PAGE,
      false,
      campaignSections.SIDE_BAR_PRICE
    )
    : {};

  return campaignData;
};

export const isBlackNovemberDate = () => {
  const startDate = new Date(seasonInfo[0].START_DATE);
  const endDate = new Date(seasonInfo[0].END_DATE);
  const today = new Date();
  return startDate < today && today < endDate;
};

export const isBlackNovember = (product, query) => {
  const currentYear = new Date().getFullYear();
  return product?.coupon &&
    (!query?.coupon || query?.coupon === product?.coupon?.code) &&
    product?.coupon?.couponType === `black_november_${currentYear}` &&
    isBlackNovemberDate()
    ? product?.coupon
    : false;
};

export const activateBlackNovemberTheme = (isBlackNovember: boolean, format: string) => {
  return isBlackNovember && format !== categories.SERVICE_ONLINE;
}
