import React from 'react';

import { Trans } from 'next-i18next'

import IconsImage from '../Images/Icons';
import { loaderStaticImage } from '../../utils/image';

const CalendarDay = loaderStaticImage({src: 'static/images/icons/calendar-day-blue.svg' });

const SessionPackageAlert = ({t}) => {
  return (
    <div className="sessionPackageAlert">
      <div className="sessionPackageAlert__content">
        <IconsImage src={CalendarDay} width={20} height={20} className="sessionPackageAlert__content__icon" />
        <div>
          <Trans i18nKey="online_service.session_alert" components={[<strong />]} />
        </div>
      </div>
    </div>
  )
}

export default SessionPackageAlert
