import React, { useContext } from 'react';
import classNames from 'classnames';
import dynamic from 'next/dynamic';
import { ProfileContext } from '../../core/Provider/ProfileProvider';
const RatingTotal = dynamic(() => import('../../RatingTotal'));
const Tag = dynamic(() => import('../../Tag'));
import { loaderStaticImage } from 'utils/image';

const BestRated = loaderStaticImage({
  src: 'static/images/icons/best_rated.svg'
});

export const renderSalesArguments = (
  showSidebarScrolled: boolean,
  showSalesArguments: boolean,
  salesArguments,
  t: (arg: string) => void,
) => {
  const context = useContext(ProfileContext);
  const {
    profile: {
      customerReview = {},
      reviews = {},
      showReviewsOnProductProfile = false,
      clubMembershipLink = '',
      totalUsers = 0
    } = {}
  } = context;

  const totalSales = salesArguments.length;

  if (salesArguments && salesArguments.length > 0) {
    return (
      <div className={classNames(`sales-argument _mb-md-5 sales-argument--${totalSales}`, {
        'sales-argument--hidden': !showSalesArguments
      })}>

      <div className="sales-argument__box">
        {showReviewsOnProductProfile && customerReview?.totalReviews && (
          <div className="sales-argument__rating">
            <RatingTotal
              ratingStars={customerReview?.rating ? customerReview?.rating.toFixed(1): customerReview?.rating }
              showReviewsOnProductProfile={showReviewsOnProductProfile}
              reviewTotalAnswers={customerReview?.totalReviews}
            />
            {showSidebarScrolled && reviews?.bestSeller && (
              <Tag
                type={`${t('sales_arguments.best_reviewed')}`}
                className={'tag-category--blue'}
                icon={BestRated}
              />
            )}
          </div>
        )}
        </div>

        <ul
          className={classNames('sales-argument__list', {
            'sales-argument__list--sticky': showSidebarScrolled
          })}
        >
          {salesArguments.map((salesArgument, index) => (
            <li
              key={`argument-${index}`}
              className={`sales-argument__list__item _text-md-1 _font-weight-bold ${salesArgument?.class}`}
              aria-label={salesArgument?.label}
            >
              {salesArgument?.label}
            </li>
          ))}
        </ul>
      </div>
    );
  }
  return null;
};

export default renderSalesArguments;
