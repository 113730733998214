import React, { useContext } from 'react';

import PropTypes from 'prop-types';
import classnames from 'classnames';

import IconsImage from '../Images/Icons';
import { loaderStaticImage } from '../../utils/image';
import { ProfileContext } from 'components/core/Provider/ProfileProvider';

const SessionPackageInfo = ({ t, info, mobile = false }) => {
  const {
    attendanceTimesPerWeek,
    numberOfSessions,
    sessionFrequency,
    attendanceSlotSize
  } = info;
  const context = useContext(ProfileContext);
  let shoppingCartIcon = loaderStaticImage({
    src: 'static/images/icons/shopping-cart-orange.svg'
  });

  if (context.profile?.isBlackNovember) {
    shoppingCartIcon = loaderStaticImage({
      src: 'static/images/black-november/shopping-cart-black-november.svg'
    });
  }

  const renderNumberOfSessionsText = () => {
    return t('online_service.session_info.number_of_sessions', {
      numberOfSessions
    });
  };

  const renderFrequencyText = () => {
    return t(
      `online_service.session_info.frequency.${sessionFrequency.toLowerCase()}`,
      { attendanceTimesPerWeek }
    );
  };

  const renderDurationText = () => {
    return t('online_service.session_info.duration', { attendanceSlotSize });
  };

  const renderMobileView = () => {
    return `${renderNumberOfSessionsText()}, ${renderFrequencyText()}, ${renderDurationText()}`;
  };

  const renderDefaultView = () => {
    return (
      <p>
        {t('online_service.session_info.title.package')}{' '}
        <strong>{renderNumberOfSessionsText()}</strong>
        <br />
        {t('online_service.session_info.title.frequency')}{' '}
        <strong>{renderFrequencyText()}</strong>
        <br />
        {t('online_service.session_info.title.duration')}{' '}
        <strong>{renderDurationText()}</strong>
      </p>
    );
  };

  return (
    <div
      className={classnames('sessionPackageInfo', {
        'sessionPackageInfo--mobile': mobile
      })}
    >
      <div
        className={classnames('sessionPackageInfo__content', {
          'sessionPackageInfo__content--mobile': mobile
        })}
      >
        <IconsImage
          src={shoppingCartIcon}
          width={20}
          height={20}
          className={classnames('sessionPackageInfo__content__icon', {
            'sessionPackageInfo__content__icon--mobile': mobile
          })}
        />
        {mobile ? renderMobileView() : renderDefaultView()}
      </div>
    </div>
  );
};

SessionPackageInfo.propTypes = {
  t: PropTypes.func.isRequired,
  info: PropTypes.shape({
    attendanceTimesPerWeek: PropTypes.number,
    numberOfSessions: PropTypes.number,
    sessionFrequency: PropTypes.string,
    attendanceSlotSize: PropTypes.number
  }),
  mobile: PropTypes.bool
};

export default SessionPackageInfo;
