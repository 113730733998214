import classnames from 'classnames';

import DropdownButton, { Props as DropdownButtonProps } from '../Button';
import IconsImage from 'components/Images/Icons';
import { loaderStaticImage } from 'utils/image';
const Button = loaderStaticImage({ src: 'static/images/icons/ellipsis.svg' });

export interface Props extends DropdownButtonProps {
  isHorizontal?: boolean;
  height?: number;
  width?: number;
}

function DropdownEllipsisButton({
  isHorizontal,
  height,
  width,
  className,
  ...props
}: Props) {
  return (
    <DropdownButton
      className={classnames(
        'hot-button--tertiary _text-2 _text-gray-500 ellipsis-button',
        className
      )}
      {...props}
    >
      <IconsImage
        src={Button}
        width={14}
        height={14}
        className="dropdown-icon"
      />
    </DropdownButton>
  );
}

export default DropdownEllipsisButton;
