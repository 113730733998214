import React, { useContext, Fragment } from 'react';
import { ProfileContext } from 'components/core/Provider/ProfileProvider';
import { withRouter } from 'next/router';
import dynamic from 'next/dynamic';
import IconsImage from '../Images/Icons';
import { loaderStaticImage } from '../../utils/image';

const Glider = dynamic(() => import('react-glider'))

type Props = {
  creditCardPaymentMethodList: [],
  customizationResponse: any,
  noSlider: boolean,
  router: any,
  schedule: boolean
}

const CreditCards = ({ creditCardPaymentMethodList, customizationResponse, noSlider, router, schedule}: Props) => {
  const { offerCreditCard, offerPaypal, offerBillet, offerSamsungPay, offerGooglePay } = customizationResponse;
  const context = useContext(ProfileContext);
  const { belongsToMarketplace } = context.profile;
  const isTransparent = router.pathname.match('checkout');

  return(
    <div className={`coCards carousel ${noSlider ? 'no-slider' : ''} ${schedule ? 'schedule-credit-cards' : ''}`}>
      {!noSlider ?
      <Glider
        draggable
        hasArrows={false}
        hasDots
        slidesToScroll={3}
        slidesToShow={3}>
          {generateCreditFlag(offerCreditCard, creditCardPaymentMethodList)}
          {generateBilet(offerBillet)}
          {generateGpay(offerGooglePay)}
          {generateSpay(offerSamsungPay)}
          {(!belongsToMarketplace && !isTransparent) && generatePayPal(offerPaypal)}
      </Glider>
      :
      <Fragment>
        {generateCreditFlag(offerCreditCard, creditCardPaymentMethodList)}
        {generateBilet(offerBillet)}
        {generateGpay(offerGooglePay)}
        {generateSpay(offerSamsungPay)}
        {(!belongsToMarketplace && !isTransparent) && generatePayPal(offerPaypal)}
      </Fragment>
      }
    </div>
  )
};

export const generateBilet = (bilet) => {
  if (bilet) {
    return <div className="credcards" id="billet">
      <IconsImage width={25} height={21} src={loaderStaticImage({src: 'static/images/icons/card-boleto.svg'})} />
    </div>
  }
};

export const generateSpay = (sPay) => {
  if (sPay) {
    return <div className="credcards" id="spay">
      <IconsImage width={25} height={21} src={loaderStaticImage({src: 'static/images/icons/card-samsungpay.svg'})} />
    </div>
  }
};

export const generateGpay = (gPay) => {
  if (gPay) {
    return <div className="credcards" id="gpay">
      <IconsImage width={25} height={21} src={loaderStaticImage({src: 'static/images/icons/card-googlepay.svg'})} />
    </div>
  }
};

export const generatePayPal = (paypal) => {
  if (paypal) {
    return <div className="credcards" id="paypal">
      <IconsImage width={25} height={21} src={loaderStaticImage({src: 'static/images/icons/card-paypal.svg'})} />
    </div>
  }
};

export const generateCreditFlag = (flag, creditCards) => {
  if (flag && creditCards) {
    return creditCards.map(card => {
      return generateCreditCards(card);
    })
  }
};

export const generateCreditCards = (creditCards) => {
  return <div className="credcards" key={creditCards} id={creditCards}>
    <IconsImage width={25} height={21} src={loaderStaticImage({src: `static/images/icons/card-${creditCards.toString().toLowerCase()}.svg`})} alt={creditCards.toString()} />
  </div>
};

export default withRouter(CreditCards);
