import React, { useState, useEffect, useContext } from 'react';
import { withTranslation } from "next-i18next";


const Advantages = ({...props}) => {
  const { schedule = [], t } = props;

  // todo Ajustar quando passar a receber programação separada
  const { description = '' } = schedule && schedule.length >= 1 && schedule[0];

  // parsing about text into paragraphs
  const scheduleArr = description.split("\n");
  const scheduleArrParsed = scheduleArr.filter(function(x){
    if (x === ' ') {
      return false;
    }
    return (x)
  });

  return (
    <div className="section" id="programacao">
      <div className="schedule-container">
        {scheduleArrParsed.length >= 1 && scheduleArrParsed.map((scheduleItem, index) => {
          return <p key={index}>{ scheduleItem }</p>;
        })}
      </div>
    </div>
  )
}

export default withTranslation()(Advantages);
