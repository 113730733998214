import { PropsWithChildren, ReactNode } from 'react'

export type HotModalElement = {
  openModal: () => void,
  closeModal: () => void
}

export type HTMLHotModalElement = HTMLDivElement & HotModalElement

export enum ModalPosition {
  CENTERED = 'centered',
  TOP = 'top'
}

export type ModalProps = PropsWithChildren<{
  className?: string,
  open?: boolean,
  docked?: boolean,
  title?: ReactNode,
  onCloseModal?: () => void,
  onOpenModal?: () => void
}>

export type CosmosModalProps = ModalProps & {
  position?: ModalPosition
  docked?: boolean
}

export interface ModalContext {
  closeModal: () => void
}
