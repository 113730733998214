import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import dynamic from 'next/dynamic';
import classnames from 'classnames';

import { validateEmail, validateName } from '../../utils/data';
import Utils from '../../global/scripts/utils';
import LeadService from '../../@lead/service';
import { formLeadSendStatus } from '../../constants';
import '@hotmart/cosmos/dist/loading';
import '@cosmos/alert/variations/success.css';
import '@cosmos/alert/variations/danger.css';
import styles from './style.module.scss';
import IconsImage from '../Images/Icons';

const Input = dynamic(() => import('components/Input/Input'), { ssr: false });
const Button = dynamic(import('components/Button/default'));
const Checkbox = dynamic(() => import('components/Input/Checkbox'), {
  ssr: false
});
const Tooltip = dynamic(() => import('components/Tooltip'), { ssr: false });

import { i18n } from 'next-i18next';

import { loaderStaticImage } from '../../utils/image';

const ShoppingCart = loaderStaticImage({
  src: 'static/images/icons/shopping-cart.svg'
});
const FormLayout = (props) => {
  const { t, isMobileDevice, setFinish, product, showFooter, tokenKlickSend } =
    props;
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [nameValid, setNameValid] = useState(-1);
  const [emailValid, setEmailValid] = useState(-1);
  const [checkbox_1, setCheckbox_1] = useState(false);
  const [checkbox_2, setCheckbox_2] = useState(false);
  const [loadingSend, setLoadingSend] = useState(false);
  const [errorMessage, seterrorMessage] = useState('');
  const inputNameRef = useRef(null);
  let buttonLabel = t('salesPage.form.button');
  let termLabel = t('salesPage.form.terms');

  const privacyPolicyLinks = {
    'pt-br': 'https://www.hotmart.com/legal/pt-BR/data-privacy-policy',
    en: 'https://hotmart.com/en/legal/data-privacy-policy',
    es: 'https://hotmart.com/es/legal/privacidad-de-datos',
    fr: 'https://hotmart.com/fr/legal/politique-de-confidentialite'
  };

  if (product?.launchType) {
    buttonLabel = t('launch.button_register');
    termLabel = t('launch.checkbox_form_confirm');
  }

  const AddButtonOnClickHandler = async () => {
    if (isMobileDevice && showFooter) {
      Utils.scrollToSection('launch_product_form');
    }

    if (!checkbox_1 || !checkbox_2) {
      seterrorMessage(t('launch.form_error_1'));
      return;
    }
    checkName(name);
    checkEmail(email);
    if (nameValid !== 1 || emailValid !== 1) return;
    setLoadingSend(true);

    try {
      if (product?.launchType) {
        await LeadService.sendLeadLaunch({
          id: tokenKlickSend,
          first_name: name,
          email: email,
          hottok: product?.productId.toString()
        });
      } else {
        await LeadService.sendLead({
          product_id: product?.productId,
          ucode: product?.hotmarter.ucode,
          fields: {
            first_name: name
          },
          email: email
        });
      }
      setFinish(formLeadSendStatus.SUCCESS);
      setLoadingSend(false);
    } catch (e) {
      setFinish(formLeadSendStatus.ERROR);
      setLoadingSend(false);
      console.error('errorSend', e);
    }
  };

  const checkName = (value, initial) => {
    const validName = validateName(value);
    seterrorMessage('');
    setNameValid(validName ? 1 : 0);

    if (initial) {
      setNameValid(initial);
    }
  };

  const checkEmail = (value, initial) => {
    seterrorMessage('');
    setEmailValid(validateEmail(value) ? 1 : 0);

    if (initial) {
      setEmailValid(initial);
    }
  };

  const labelCheckbox_2 = (value) => {
    return (
      <>
        {t('launch.checkbox_form_confirm_2')}
        <button
          className={classnames(styles['privacity-button'], {
            [styles['text-bn']]: !isMobileDevice
          })}
          onClick={() =>
            window.open(
              privacyPolicyLinks[i18n?.language] || privacyPolicyLinks['pt-br']
            )
          }
        >
          {t('launch.checkbox_form_confirm_2_link_text')}
        </button>
      </>
    );
  };

  return (
    <>
      <div ref={inputNameRef} tabIndex={-1} id="launch_product_form">
        <Input
          inputClassName={classnames({
            [styles['input_invalid']]: !nameValid,
            [styles['input_valid']]: nameValid === formLeadSendStatus.SUCCESS
          })}
          label={t('launch.input_label_name')}
          placeholder={t('launch.input_placeholder_name')}
          success={nameValid}
          helperSuccessClassName={classnames({
            [styles['valid-feedback-bn']]: !isMobileDevice
          })}
          helperErrorClassName={classnames({
            [styles['invalid-feedback-bn']]: !isMobileDevice
          })}
          successMessage={
            nameValid === 0
              ? t('launch.input_error_name')
              : t('salesPage.form.name')
          }
          inputIcon="static/images/icons/user.svg"
          labelProps={{ isRequired: true, requiredMessage: '*' }}
          onChange={(e) => {
            setName(e.target.value);
          }}
          onBlur={(e) => {
            checkName(e.target.value);
          }}
        />
        <Input
          inputClassName={classnames({
            [styles['input_invalid']]: !emailValid,
            [styles['input_valid']]: emailValid === formLeadSendStatus.SUCCESS
          })}
          label={t('launch.input_label_email')}
          placeholder={t('launch.input_placeholder_email')}
          success={emailValid}
          helperSuccessClassName={classnames({
            [styles['valid-feedback-bn']]: !isMobileDevice
          })}
          helperErrorClassName={classnames({
            [styles['invalid-feedback-bn']]: !isMobileDevice
          })}
          successMessage={
            emailValid === 0
              ? t('launch.input_error_email')
              : t('salesPage.form.email')
          }
          inputIcon="static/images/icons/social-media/envelope_gray.svg"
          labelProps={{ isRequired: true, requiredMessage: '*' }}
          onChange={(e) => {
            setEmail(e.target.value);
          }}
          onKeyUp={(e) => {
            setTimeout(() => {
              if (e.target.value?.length > 5) {
                checkEmail(e.target.value);
              }
            }, 500);
          }}
        />
        <Checkbox
          id="ck_1"
          checked={checkbox_1}
          onChange={(e) => {}}
          onClick={() => setCheckbox_1(!checkbox_1)}
          label={termLabel}
          isRequired={true}
          labelClassName={styles.checkbox_label}
        />
        <Checkbox
          id="ck_2"
          checked={checkbox_2}
          onChange={(e) => {}}
          onClick={() => setCheckbox_2(!checkbox_2)}
          label={labelCheckbox_2()}
          isRequired={true}
          labelClassName={styles.checkbox_label}
        />
      </div>

      <div
        className={classnames({
          [styles['footer']]: showFooter
        })}
      >
        {!loadingSend && (
          <Tooltip message={errorMessage} position="top">
            <Button
              onClick={AddButtonOnClickHandler}
              variation="success"
              size="md"
              disabled={loadingSend}
              className={classnames(
                `_d-flex _align-items-center _justify-content-center ${styles['btn-buy']}`,
                { '_p-4': isMobileDevice }
              )}
            >
              <span className="_mr-1">{buttonLabel}</span>
              {isMobileDevice && (
                <IconsImage
                  src={ShoppingCart}
                  width={20}
                  height={20}
                  className="link-button__icon"
                />
              )}
            </Button>
          </Tooltip>
        )}
        {loadingSend && (
          <div
            className={classnames('_d-flex _justify-content-center', {
              '_h-10 _mb-2': isMobileDevice
            })}
          >
            <hot-loading></hot-loading>
          </div>
        )}
      </div>
    </>
  );
};

FormLayout.propTypes = {
  t: PropTypes.func,
  isMobileDevice: PropTypes.bool,
  setFinish: PropTypes.func,
  product: PropTypes.object,
  tokenKlickSend: PropTypes.string
};

export default FormLayout;
