import EventAgent from '@hotmart/event-agent-js';

import { currentEnv } from '../../env.config';

import { REQUIRED_FIELDS, EVENT_PROPS } from './datahub.constants';

const getCommonEventDataByAction = action => {
  const eventData = EVENT_PROPS[action];

  if (!eventData) {
    throw new Error(`Invalid datahub action. Received: ${action}`);
  }
  return { ...eventData };
};

const validateEventRequiredFields = (event, eventData) => {
  const actionRequiredFields = REQUIRED_FIELDS[event.action];
  const isInvalid = actionRequiredFields?.some(field => !event[field]);

  if (isInvalid) {
    console.error(`Invalid datahub event data. Received: ${JSON.stringify(eventData, null, 2)}`);
  }
};

export default {
  sendEvent(event) {
    const { action, ...restEvent } = event;

    const eventData = getCommonEventDataByAction(action);

    eventData.event = restEvent;
    if (restEvent.system) {
      eventData.system = restEvent.system;
    }

    validateEventRequiredFields(event, eventData);

    if (currentEnv !== 'production') {
      // eslint-disable-next-line no-console
      console.log("DATAHUB EVENT: ", JSON.stringify(eventData, null, 2))
    }

    EventAgent.mode = currentEnv === 'production' ? 'production' : 'staging'
    EventAgent.send(eventData);
  }
};
