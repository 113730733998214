import { i18n } from 'next-i18next';
// import { getLocalei18n } from '../components/Calendar/DatePicker';
import Utils from '../../src/global/scripts/utils';

const dayjs = require('dayjs')
const localizedFormat = require('dayjs/plugin/localizedFormat')
const utc = require('dayjs/plugin/utc')
dayjs.extend(utc)
dayjs.extend(localizedFormat)

require('dayjs/locale/es')
require('dayjs/locale/en')
require('dayjs/locale/pt-br')
require('dayjs/locale/fr')

export const calculateCountdown = (endDate) => {
  let diff = (Date.parse(new Date(endDate)) - Date.parse(new Date())) / 1000;

  // clear countdown when date is reached
  if (diff <= 0) return false;

  const timeLeft = {
    years: 0,
    days: 0,
    hours: 0,
    min: 0,
    sec: 0,
    millisec: 0
  };

  // calculate time difference between now and expected date
  if (diff >= 365.25 * 86400) {
    // 365.25 * 24 * 60 * 60
    timeLeft.years = Math.floor(diff / (365.25 * 86400));
    diff -= timeLeft.years * 365.25 * 86400;
  }
  if (diff >= 86400) {
    // 24 * 60 * 60
    timeLeft.days = Math.floor(diff / 86400);
    diff -= timeLeft.days * 86400;
  }
  if (diff >= 3600) {
    // 60 * 60
    timeLeft.hours = Math.floor(diff / 3600);
    diff -= timeLeft.hours * 3600;
  }
  if (diff >= 60) {
    timeLeft.min = Math.floor(diff / 60);
    diff -= timeLeft.min * 60;
  }
  timeLeft.sec = diff;

  return timeLeft;
};

export const formatDateLocale = (date, dateFormat) => {
  let formatedDate;
  formatedDate = dateFormat ? date : new Date(Date.parse(`${date} GMT`));
  const formart = Utils.getLanguageSymbol(i18n.language) !== 'en' ? 'DD/MM/YYYY' : 'L';

  try {
    formatedDate = dayjs(formatedDate).format(formart);
  } catch(e) {
    console.error(e);
  }

  return formatedDate;
};

export const convertDateGTM = (date) => {
  return date ? new Date(Date.parse(`${date} GMT`)) : date;
}

export const convertUTC = (date) => {
  return dayjs(date).utc('z').local().format()
}

export const formatLocale = (date, locale, dateFormat) => {
  let dateLocale;

  if(dateFormat) {
    dateLocale = dayjs(date).locale(getLocale(locale)).format(dateFormat)
  } else {
    dateLocale = dayjs(date).format()
  }

  return dateLocale;
}

export const dateAddYear = (date, time) => {
  return dayjs(date).add(time, 'year')
}

export const getLocale = (language) => {
  if (language) {
    if (language.match(/pt/)) {
      return 'pt-br';
    } else if (language.match(/en/)) {
      return 'en';
    } else if (language.match(/es/)) {
      return 'es';
    } else if (language.match(/fr/)) {
      return 'fr';
    }
  }
  return 'en';
};

export const addLeadingZeros = (value) => {
  value = String(value);
  while (value.length < 2) {
    value = `0${value}`;
  }
  return value;
};
