import { productQueryString, projectName } from '../constants';

export const extractElement = (element: string) => {
  const regEx = /([^>]+$)/;
  let tag = '';

  if (element && typeof element === 'string') {
    const data = element?.match(regEx);
    tag = data?.length > 0 ? data[0] : '';
  }

  return tag;
};

export const extractPromptAI = (data: [PropsPrompt], term: string) => {
  if (data?.length > 0) {
    const textAI = data.find((item) => item.publicId === term);
    return textAI?.text || null;
  }

  return null;
};

export const extractProjectName = (sck: string | undefined) => {
  return sck && sck === productQueryString?.PRODUCT_MARKETPLACE[0].value
    ? projectName.MARKETPLACE
    : projectName.PRODUCT;
};

export const convertStringToSearch = (text: string) => {
  if (text) {
    return text?.replace(/ /g, '+').replace(/"/g, '&quot;');
  }

  return '';
};

export const mountFormatProduct = (
  product: ProductInfo,
  t: (arg1: string) => string,
  isMobile = false
) => {
  const separator = isMobile ? '-' : '.';
  const productTopic = product?.hotTopic?.topic
    ? `${separator} ${t(`topic.${product?.hotTopic?.topic}`)}`
    : '';

  return `${t(product?.format)} ${productTopic}`;
};
