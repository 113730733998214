import { ButtonHTMLAttributes } from 'react';

import classnames from 'classnames';
import '@hotmart/cosmos/dist/dropdown/dropdown-toggle.css';

export interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  custom?: boolean,
  toggle?: boolean,
  className?: string
}

function DropdownButton({
  children,
  custom = false,
  toggle = false,
  className,
  ...rest
}: Props) {
  return (
    <button
      type="button"
      slot="button"
      className={classnames(className, {
        'hot-dropdown__toggle': toggle,
        'hot-button': !custom
      })}
      {...rest}
    >
      {children}
    </button>
  );
}

export default DropdownButton;
