import { getQueryParams } from './url';

export const isValidVideoURL = (url) => {
  return isVimeoVideo(url) || isYoutubeVideo(url);
};

const validVideo = (url, arrayOfAllowedHosts) => {
  const videoURL = URLFactory(url);
  if (
    videoURL &&
    Array.isArray(arrayOfAllowedHosts) &&
    arrayOfAllowedHosts.length > 0 &&
    arrayOfAllowedHosts.indexOf(videoURL.host) !== -1
  ) {
    return true;
  }
  return false;
};

export const isVimeoVideo = (url) => {
  return validVideo(url, [
    'player.vimeo.com',
    'vimeo.com',
    'www.vimeo.com',
    'www.player.vimeo.com'
  ]);
};

export const isYoutubeVideo = (url) => {
  return validVideo(url, ['www.youtube.com', 'youtube.com', 'youtu.be']);
};

export const generateYoutubeURL = (code) => {
  return `https://www.youtube.com/embed/${code}`;
};

export const generateVimeoURL = (videoCode) => {
  return `https://player.vimeo.com/video/${videoCode}?title=0&byline=0&portrait=0`;
};

export const generateVideoEmbed = (url) => {
  try {
    if (isVimeoVideo(url)) {
      const videoCode = extractVimeoVideoCode(url);
      return generateVimeoURL(videoCode);
    } else if (isYoutubeVideo(url)) {
      const videoCode = extractYoutubeVideoCode(url);
      return generateYoutubeURL(videoCode);
    }
  } catch (err) {
    return false;
  }
  return false;
};

export const getVideoCodeAndPlayer = (url) => {
  try {
    if (isVimeoVideo(url)) {
      return {
        player: 'VIMEO',
        code: extractVimeoVideoCode(url),
      };
    } else if (isYoutubeVideo(url)) {
      return {
        player: 'YOUTUBE',
        code: extractYoutubeVideoCode(url)
      }
    }
  } catch (err) {
    console.log(err);
  }
  return {
    player: '',
    code: null,
  };
};

export const extractVimeoVideoCode = (url) => {
  const builtURL = URLFactory(url);
  if (builtURL) {
    const pathnameSplitted = builtURL.pathname.substring(1).split('/');
    if (pathnameSplitted && pathnameSplitted.length > 0) {
      if (pathnameSplitted[0] === 'video' && pathnameSplitted[1]) {
        // Casos de exemplo
        // https://player.vimeo.com/video/285852594
        return pathnameSplitted[1];
      } else if (pathnameSplitted[0] && pathnameSplitted[1]) {
        // Casos de exemplo
        // https://vimeo.com/282720437/0c9fff2ac5
        return `${pathnameSplitted[0]}/${pathnameSplitted[1]}`
      } else {
        // Casos de exemplo
        // https://vimeo.com/285852594
        return pathnameSplitted[0];
      }
    }
  }
  throw new Error(`Video code not found at ${url}`);
};

export const extractYoutubeVideoCode = (url) => {
  const builtURL = URLFactory(url);
  if (url.match(/v=/)) {
    // Casos de exemplo
    // https://www.youtube.com/watch?v=O2tGM2vOiqI&t=36s
    // https://www.youtube.com/watch?v=O2tGM2vOiqI
    const { v } = getQueryParams(builtURL);
    if (v) {
      return v;
    }
    throw new Error(`Video code not found at ${url}`);
  } else if (url.match(/\/embed/)) {
    // Casos de exemplo
    // https://www.youtube.com/embed/CErNgXDzbpA
    // https://www.youtube.com/embed/CErNgXDzbpA?a
    const matchResult = builtURL.pathname.match(/\/embed\/([^\/?&]+)(.*)/);
    if (matchResult && matchResult[1]) {
      return matchResult[1];
    }
    throw new Error(`Video code not found at ${url}`);
  } else if (url.match(/youtu\.be/)) {
    // Casos de exemplo
    // https://youtu.be/CErNgXDzbpA
    // https://youtu.be/CErNgXDzbpA?a
    const matchResult = builtURL.pathname.match(/([^\/?&]+)(.*)/);
    if (matchResult && matchResult[0]) {
      return matchResult[0];
    }
    throw new Error(`Video code not found at ${url}`);
  } else {
    throw new Error(`Video code not found at ${url}`);
  }
};

const URLFactory = (url) => {
  try {
    return new URL(url);
  } catch (err) {}
  return null;
};
