import React, { memo } from 'react'

import PropTypes from 'prop-types'

import '@cosmos/button/button.css'
import '@cosmos/button/variations/primary.css'
import '@cosmos/button/variations/secondary.css'
import '@cosmos/button/variations/tertiary.css'
import '@cosmos/button/variations/large.css'
import '@cosmos/button/variations/small.css'
import '@cosmos/button/variations/info.css'
import '@cosmos/button/variations/success.css'
import '@cosmos/button/variations/danger.css'
import '@cosmos/button/variations/warning.css'

const constructClassNames = (variation, size, className = '') => {
  const variationClass = ` hot-button--${variation}`
  const sizeClass = size ? ` hot-button--${size}` : ''

  return `hot-button${variationClass}${sizeClass} ${className}`.trim()
}

const Button = ({
  children,
  onClick,
  type = 'button',
  variation = 'primary',
  size,
  disabled = false,
  className = '',
  dataTestId = '',
  wrapperClassName = '',
  ...props
}) => {
  return (
    <div className={wrapperClassName}>
      <button
        data-testid={dataTestId || 'button-component'}
        type={type}
        disabled={disabled}
        className={constructClassNames(variation, size, className)}
        onClick={onClick}
        {...props}>
        {children}
      </button>
    </div>
  )
}

Button.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
  type: PropTypes.oneOf(['submit', 'button', 'reset']),
  variation: PropTypes.oneOf(['primary', 'secondary', 'tertiary', 'link', 'info', 'success', 'danger', 'warning']),
  size: PropTypes.oneOf(['sm', 'lg', 'md']),
  disabled: PropTypes.bool,
  className: PropTypes.string,
  dataTestId: PropTypes.string,
  wrapperClassName: PropTypes.string
}

export default memo(Button)
