export const scrollSpeed = (
  positionScroll: number,
  prevPositionScroll: number | null
) => {
  let timer;
  let headerScroll;
  let durationScroll = 0;
  const durationMax = 10;

  if (prevPositionScroll != null) {
    durationScroll = positionScroll - prevPositionScroll;
  }

  prevPositionScroll = positionScroll;
  timer && clearTimeout(timer);
  timer = setTimeout(() => {
    prevPositionScroll = null;
  }, 30);

  if (durationScroll < 0 && Math.abs(durationScroll) >= durationMax) {
    headerScroll = true;
  }

  if (durationScroll > durationMax / 2) {
    headerScroll = false;
  }

  return {
    headerScroll,
    prevPositionScroll
  };
};

export const scrollHeader = (sidebarScrolled, showHeaderScroll, header) => {
  if (sidebarScrolled && !showHeaderScroll) {
    header?.classList.add('header-marketplace--sticky');
    header?.classList.remove('header-marketplace--fadeIn');
  } else {
    header?.classList.remove('header-marketplace--sticky');

    if (showHeaderScroll) {
      header?.classList.add('header-marketplace--fadeIn');
    }
  }
};
