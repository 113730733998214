import React from 'react';
import { Offer, Course } from 'schema-dts';
import { JsonLd } from 'react-schemaorg';
import { mountProductOffer, mountProductReview } from './options';
import Utils from 'global/scripts/utils';
import sanitizeHtml from 'sanitize-html';
import { convertStringToSearch } from 'utils/string'

type StructuredDataProps = {
  product: ProductInfo;
  checkout: DataCheckoutProps;
  reviews: CustomerReviewProps;
};

export const StructuredData = (props: StructuredDataProps) => {
  const { product } = props;
  const aggregateRating = mountProductReview(props?.reviews);
  const offers = mountProductOffer(props.product, props.checkout) as Offer;
  const durationDefaultInHours = 0;
  const duration = `PT${product.dataSheet.duration || durationDefaultInHours}H`; //  formato de duração ISO 8601

  return (
    <JsonLd<Course>
      item={{
        '@context': 'https://schema.org',
        '@type': 'Course',
        publisher: {
          "@type": "Organization",
          name: "Hotmart",
          sameAs: "hotmart.com"
        },
        provider: {
          "@type": "Organization",
          name: sanitizeHtml(product?.hotmarter?.name),
          sameAs: `hotmart.com/${Utils.verifyLanguage(product.language)}/marketplace/${Utils.prepareForProductPage(Utils.verifyLanguage(product.language))}?q=${convertStringToSearch(product.hotmarter?.name)}`
        },
        "@id": `${Utils.mountMainUrlSalesPage(product.language)}/${product.slug}/${product.producerReferenceCode}`,
        name: sanitizeHtml(product.name?.slice(0, 120)),
        description: sanitizeHtml(product.copy?.slice(0, 120)) || '',
        isAccessibleForFree: false,
        image: product.avatarFinal || product.avatar || 'https://hotmart.com/product/static/images/placeholder/product.png',
        inLanguage: product.language,
        hasCourseInstance: {
          "@type": "CourseInstance",
          courseMode: [
            "distance learning",
            "online"
          ],
          startDate: product.publicationDate,
          location: {
            "@type": "Place",
            name: "Online",
            address: "Online"
          },
          courseWorkload: duration,
          ...(offers ? { offers } : {}),
        },
        ...(product.dataSheet.certification ? { educationalCredentialAwarded: {
          "@type": "EducationalOccupationalCredential",
          name: "Hotmart Certificate",
          credentialCategory: "Certificate",
          url: `https://help.hotmart.com/${Utils.verifyLanguage(product.language)}/section/conteudo-e-certificado/9195708307597`
        }} : {}),
        ...(aggregateRating ? { aggregateRating } : {})
      }}
    />
  );
};

export default React.memo(StructuredData);
