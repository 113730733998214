import React from 'react';

import '@cosmos/form/input-validate.css';
import styles from './style.module.scss';

type Props = {
  success: number;
  successMessage: string;
  helperText: string;
  helperSuccessClassName: string;
  helperErrorClassName: string;
};

const HelperText = ({
  success = -1,
  successMessage = '',
  helperText = '',
  helperSuccessClassName = '',
  helperErrorClassName = ''
}: Props) => (
  <>
    {success === 0 && (
      <div
        className={`invalid-feedback ${styles['d-block']} ${helperErrorClassName}`}
      >
        {successMessage}
      </div>
    )}
    {success === 1 && (
      <div
        className={`valid-feedback ${styles['d-block']} ${helperSuccessClassName}`}
      >
        {successMessage}
      </div>
    )}
    {!success && helperText && (
      <div className={styles['helper-text']}>{helperText}</div>
    )}
  </>
);

export default HelperText;
