import React, { useState } from 'react';
import Utils from '../../global/scripts/utils';
import { withTranslation } from 'next-i18next';
import RatingTotal from 'components/RatingTotal';
import { formatDateLocale } from '../../utils/date';
import IconsImage from '../Images/Icons';
import { loaderStaticImage } from '../../utils/image';

const Arrow = loaderStaticImage({src: 'static/images/icons/arrow.svg' });

const ReviewsRows = (props) => {
  const { rating, date, review, userName, showReviewsOnProductProfile, t } = props;
  const [showMore, setShowMore] = useState(false)
  const reviewSize = review?.length || 0;
  const reviewTotal = 200;

  const renderReview = () => {
    return (
      <div className="review-product review-box">
        <div className="review-product__header">
          <RatingTotal 
            ratingStars={rating} 
            showReviewsOnProductProfile={showReviewsOnProductProfile}/>
          <div className="review-product__date">{formatDateLocale(date, true)}</div>
        </div>
        <div className="review-product__text">
          {showMore ? review : Utils.substringText(review, reviewTotal)}
          {reviewSize > reviewTotal ? (
            <span onClick={()=> setShowMore(!showMore)} className="review-product__icon">
              <IconsImage src={Arrow} className={`arrow ${showMore ? 'arrow-down' : 'arrow-up'}`} width={12} height={12}/>
            </span>
          ): null}
        </div>
        <div className="review-product__hotmarter">
          <span>{userName}</span>
        </div>
      </div>
    );
  };

  return renderReview()
}

ReviewsRows.defaultProps = {};

export default withTranslation()(React.memo(ReviewsRows));
