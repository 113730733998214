import React from 'react';
import ContentLoader from 'react-content-loader';

export const LoadingTag = ({ ...props }) => {
  return (
    <div
      className={`loading-${props.type}`}
      style={{
        width: `100%`,
        justifyContent: `${props.align ? props.align : 'start'}`
      }}
    >
      <div style={{ height: `${props.height}px`, width: `${props.width}px` }}>
        <ContentLoader
          height={props.height}
          width={props.width}
          speed={1}
          primaryColor="#f3f3f3"
          secondaryColor="#ecebeb"
        >
          <rect
            x="0"
            y="0"
            rx={props.radiusX}
            ry={props.radiusY}
            width={props.width}
            height={props.height}
          />
        </ContentLoader>
      </div>
    </div>
  );
};
