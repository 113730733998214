import React from 'react';
import IconsImage from '../Images/Icons';

type Props = {
  className?: string;
  type: string;
  tabIndex?: number;
  icon?: string;
};

const Tag = ({ className, type, tabIndex, icon }: Props) => {
  return (
    <div className={`tag-format ${className}`}>
      {icon && (
        <IconsImage src={icon} width={12} height={12} className="avatar-icon" />
      )}
      <div
        className={`tag-category tag-category--title `}
        tabIndex={tabIndex}
      >
        {type}
      </div>
    </div>
  );
};

export default React.memo(Tag);
