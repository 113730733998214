import { forwardRef, ForwardRefExoticComponent, RefAttributes, useEffect } from 'react'

import classnames from 'classnames'

import '@hotmart/cosmos/dist/modal'

import ModalBody from './modalBody'
import ModalFooter from './modalFooter'
import { CosmosModalProps as Props, ModalContext, HTMLHotModalElement, ModalPosition } from './types'
import Context from './context'
import { OPEN_MODAL_EVENT, CLOSE_MODAL_EVENT } from './constants'

interface Compound extends ForwardRefExoticComponent<Props & RefAttributes<HTMLHotModalElement>> {
  Body: typeof ModalBody,
  Footer: typeof ModalFooter
}

const Modal = forwardRef<HTMLHotModalElement, Props>(({
  className,
  children,
  open,
  position = ModalPosition.CENTERED,
  docked = false,
  onCloseModal,
  onOpenModal
}, forwardedRef) => {
  const mutableRef = typeof forwardedRef === 'function' ? null : forwardedRef

  const contextValue: ModalContext = {
    closeModal: () => mutableRef?.current?.closeModal()
  }

  useEffect(() => {
    const currentRef = mutableRef?.current

    onCloseModal && currentRef?.addEventListener(CLOSE_MODAL_EVENT, onCloseModal)
    onOpenModal && currentRef?.addEventListener(OPEN_MODAL_EVENT, onOpenModal)

    return () => {
      onCloseModal && currentRef?.removeEventListener(CLOSE_MODAL_EVENT, onCloseModal)
      onOpenModal && currentRef?.removeEventListener(OPEN_MODAL_EVENT, onOpenModal)
    }
  }, [onCloseModal, onOpenModal, mutableRef])

  return (
    <Context.Provider value={contextValue}>
      <hot-modal
        class={classnames(className, {
          'hot-dropdocked': docked
        })}
        {...(position && { position })}
        {...(open && { open })}
        ref={mutableRef}>
        {children}
      </hot-modal>
    </Context.Provider>
  )
}) as Compound

Modal.Body = ModalBody
Modal.Footer = ModalFooter

export default Modal
export { default as ModalFooter } from './modalFooter'
export { default as ModalBody } from './modalBody'
export * from './constants'
export * from './hooks'
export * from './types'
export { default as Context } from './context'
