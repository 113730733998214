import { getAccessLinks } from '../../../utils/checkout';

const mountAccessLink = (
  accessCourse: any,
  config: any,
  isBuyable: boolean,
  clubMembershipLink: string,
  product: ProductInfo,
  context?: TypeContext
) => {
  let { accessCourseLink, showAccessCourse, showCheckout } = getAccessLinks(
    accessCourse,
    config,
    isBuyable,
    clubMembershipLink
  );

  if (showAccessCourse && !accessCourseLink) {
    showAccessCourse = false;
    showCheckout = true;
  }
  if (!context?.profile?.salesEnabled) {
    showCheckout = false;
  }

  return {
    accessCourseLink,
    showAccessCourse,
    showCheckout
  };
};

export default mountAccessLink;
