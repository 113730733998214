import React from 'react';
import { withTranslation } from 'next-i18next';
import Rating from '../Rating';

const RatingMainBox = (props) => {
  const {averageRating, totalAnswers, t} = props;
  return (
    <div className="rating-main-box">
      <div className="rating-main-box-grade">{averageRating}</div>
      <div className="rating-main-box-ratings-qty">
        <Rating value={averageRating} showRegularAsSolid />
        <span className="rating-main-box-many-reviews">
          {t('reviews.many_reviews', {
            totalReviews: totalAnswers
          })}
        </span>
      </div>
    </div>
  );
};

export default withTranslation()(RatingMainBox);
