import React from 'react';
import styles from './style.module.scss';

type Props = {
  product: ProductInfo;
  t(arg: string): string;
};

const Header = ({ product, t }: Props) => {
  if (!product?.launchType) {
    return (
      <>
        <div className={styles.subtitle}>{t('salesPage.form.title')}</div>
        <div className={styles.subtitle__small}>
          {t('salesPage.form.description')}
        </div>
      </>
    );
  }
};

export default Header;
