import React from 'react'
import PropTypes from 'prop-types'

import '@cosmos/form/form.css'
import '@cosmos/form/input-default.css'
import '@cosmos/input-group/input-group.css'

import { Label } from '..'
import HelperText from '../HelperText'
import IconsImage from '../../Images/Icons';
import { loaderStaticImage } from '../../../utils/image';

import styles from './style.module.scss';

const Input = React.forwardRef(
  (
    {
      id,
      label,
      success,
      successMessage,
      helperText,
      className,
      inputClassName,
      helperSuccessClassName,
      helperErrorClassName,
      onFocus,
      onBlur,
      labelProps,
      onChange,
      placeholder,
      inputIcon,
      ...props
    },
    ref
  ) => {

    return (
      <div className={`hot-form ${styles.input} ${className}`}>
        {label && (
          <Label
            className={"hot-form__label"}
            htmlFor={id}
            text={label}
            {...labelProps}
          />
        )}
        {/** Default Input */}
        <div className={styles.wrapper}>
          <input
            ref={ref}
            className={`hot-form__input ${success === '0' ? 'is-invalid' : ''} ${success === '1' ? 'is-valid' : ''}  ${inputClassName}`}
            id={id}
            onChange={onChange}
            onBlur={onBlur}
            onFocus={onFocus}
            placeholder={placeholder}
            {...props}
          />
          {inputIcon &&
            <div className={styles["input-icon"]}>
              <IconsImage width={18} height={18} src={loaderStaticImage({ src: inputIcon })} />
            </div>
          }
        </div>

        <HelperText success={success} successMessage={successMessage} helperText={helperText} helperSuccessClassName={helperSuccessClassName} helperErrorClassName={helperErrorClassName} />
      </div>
    )
  }
)

Input.displayName = 'Input'

Input.propTypes = {
  id: PropTypes.oneOf([PropTypes.string, PropTypes.number]),
  label: PropTypes.string,
  success: PropTypes.number,
  successMessage: PropTypes.string,
  helperText: PropTypes.string,
  className: PropTypes.string,
  inputClassName: PropTypes.string,
  helperSuccessClassName: PropTypes.string,
  helperErrorClassName: PropTypes.string,
  inputIcon: PropTypes.string,
  placeholder: PropTypes.string,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  labelProps: PropTypes.object,
  onChange: PropTypes.func
}

Input.defaultProps = {
  id: null,
  label: '',
  success: '',
  successMessage: '',
  helperText: '',
  inputClassName: '',
  helperSuccessClassName: '',
  helperErrorClassName: '',
  inputIcon: '',
  placeholder: '',
  onFocus: () => {},
  onBlur: () => {},
  labelProps: {},
  onChange: () => {}
}

export default Input
