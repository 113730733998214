import common from './common.json';

export function getLanguagePrefix(language) {
  return `${language || ''}`.split('-')[0];
}

export function getCommonByLanguage(language) {
  const prefix = getLanguagePrefix(language);
  return (common && common[prefix]) || {};
}
