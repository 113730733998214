import { useEffect, useState } from 'react';
import AuthService, { Bootstrap } from '@hotmart/cas-js';

const config = require('../../../env.config');

const useAuthModule = ({ store }) => {
  const [user, setUser] = useState({});
  let bootstrap = {};

  useEffect(() => {
    const userCas = JSON.parse(localStorage.getItem('cas-js:user'));
    const userRedefined = {
      timeZone:
        Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone ||
        'America/SaoPaulo',
      urlPhoto:
        'https://hotmart.com/product/static/images/placeholder/avatar.png'
    };

    if (userCas) {
      setUser({
        ...userCas.profile,
        ...userRedefined
      });
      store?.actions.incrementUser({ ...userRedefined, ...userCas?.profile });
      store?.actions.setToken(userCas?.id_token);

      const auth = new AuthService({
        client_id: config.envConfig.CLIENT_ID,
        scope: 'openid profile authorities',
        includeIdTokenInSilentRenew: true,
        automaticSilentRenew: true,
        filterProtocolClaims: true,
        loadUserInfo: true,
        env: config.currentEnv === 'production' ? 'production' : 'development'
      });

      bootstrap = new Bootstrap(auth, {
        signinRedirect: false, appName: 'app-productpage'
      });
      bootstrap.subscribe({
        next: user => {
          if (user) {
            setUser({ ...user.profile, ...userRedefined });
            store?.actions.incrementUser({
              ...user.profile,
              ...userRedefined
            });
            store?.actions.setToken(user.id_token);
          } else {
            store?.actions.incrementUser({ name: '', authorities: [] });
            setUser({ name: '', authorities: [] });
          }
        }
      });
      bootstrap.start();
    }
  }, []);

  return {
    user
  };
};

export default useAuthModule;
