import React from 'react';
import { withTranslation } from 'next-i18next';
import IconGreen from '/static/images/icons/icon-check.svg';

const WebinarComponent = ({ ...props }) => {
  const { t } = props;

  return (
    <section className="section section-divider" id="webinar">
      <div className="webinar__advantages">
        <h5>{t("online_event.title")}</h5>
        <p className="webinar__description">{t("online_event.description")}</p>
        <div className="webinar__banner mobile">
          <img src="/product/static/images/placeholder/event_online.png" alt=""/>
        </div>
        <div className="webinar__presentation">
          <IconGreen />
          <p>{t("online_event.apresentation_interaction")}</p>
        </div>
        <div className="webinar__presentation">
          <IconGreen />
          <p>{t("online_event.apresentation_area")}</p>
        </div>
      </div>
      <div className="webinar__banner desktop">
        <img src="/product/static/images/placeholder/event_online.png" alt=""/>
      </div>
    </section>
  )
};

export default withTranslation()(React.memo(WebinarComponent));
