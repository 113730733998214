import React from 'react';
import Rating from 'components/Rating';
import Utils from '../../global/scripts/utils';

type Props = {
  ratingStars: number;
  showReviewsOnProductProfile: boolean;
  reviewTotalAnswers?: number;
  isMobile?: boolean;
  showIcon?: boolean;
  className?: string;
};

const RatingTotal = ({
  ratingStars,
  reviewTotalAnswers,
  showReviewsOnProductProfile,
  isMobile = false,
  showIcon = true,
  className
}: Props) => {
  const scrollToReview = () => {
    if (!isMobile) {
      Utils.scrollToSection('review');
    }
  };

  if (ratingStars && showReviewsOnProductProfile) {
    return (
      <div className={className}>
        {!showIcon && <Rating value={ratingStars} showRegularAsSolid />}
        <div className="tags__rating-global">
          <span className="rating-total">{ratingStars}</span>
          {showIcon && <Rating value={ratingStars} showRegularAsSolid />}
          {reviewTotalAnswers > 0 && (
            <span
              className="tags__rating-global__total-reviews _text-gray-900"
              onClick={() => scrollToReview()}
            >
              ({reviewTotalAnswers})
            </span>
          )}
        </div>
      </div>
    );
  } else {
    return null;
  }
};

export default React.memo(RatingTotal);
