export enum DropdownPosition {
  BOTTOM = '',
  LEFT = 'hot-dropleft',
  RIGHT = 'hot-dropright',
  TOP = 'hot-dropup'
}

export enum DropdownSide {
  LEFT = 'left',
  RIGHT = 'right'
}
