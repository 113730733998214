import Request from '@hotmart/request';
import { Constants } from './request';

class CampaignService {
  async getInfoCampaign (slug) {
    try {
      return Request(Constants.INFO_CAMPAIGN, slug);
    } catch (err) {
      console.error(err);
      return;
    }
  };
}

export default new CampaignService();