import { getCommonByLanguage } from '../../static/locales/common';

export const translateSuggestionsList = (t, list) => {
  if (list && Array.isArray(list)) {
    return list
      .map((item) => {
        return { key: item, value: t(`search.terms.${item}`) };
      })
      .filter((item) => !!item || !!item.value);
  }
  return [];
};

export const loadSearchData = (t, language) => {
  const localizedString = getCommonByLanguage(language);
  const {
    placeholderOptions: { option1, option2, option3 } = {},
    suggestions
  } = localizedString;

  // translate suggestions
  const suggestionsList = translateSuggestionsList(t, suggestions);

  const placeholder = `${t('search.example', {
    option1: t(`search.terms.${option1}`),
    option2: t(`search.terms.${option2}`),
    option3: t(`search.terms.${option3}`)
  })}`;

  return {
    placeholder,
    suggestions: suggestionsList
  };
};
