import React, { useState, useRef } from 'react';

import Image from 'next/image';
import dynamic from 'next/dynamic';
import classnames from 'classnames';

import {
  loaderImage,
  generateSrcSet,
  loaderStaticImage
} from '../../../utils/image';

const ProductImageModal = dynamic(
  () => import('components/@redesign/ProductImageModal'), { ssr: false }
);

const productImageFallback = `static/images/placeholder/product.png`;

type Props = {
  src: string;
  isMobile?: boolean;
  avatar?: string;
  className?: string;
  priority?: boolean;
  quality?: number;
  campaignStrip?: {};
  alt?: string;
  coupon?: string;
  width?: number;
  height?: number;
  layout?: "fixed" | "responsive" | "fill" | "intrinsic";
  placeholder?: "blur" | "empty";
  tabIndex?: number;
  blurDataURL?: string;
  isRedesign?: boolean;
  'data-testid'?: string;
}

const ProductImage = ({
  src = loaderStaticImage({ src: productImageFallback }),
  isMobile = false,
  avatar,
  className,
  priority,
  quality,
  campaignStrip,
  alt,
  width,
  height,
  layout,
  tabIndex,
  blurDataURL,
  placeholder,
  isRedesign = false,
  'data-testid': dataTestid
}: Props) => {
  const [lastImage, setLastImage] = useState('');
  const imageModalRef = useRef(null);
  const showStrip =
    campaignStrip && Object.keys(campaignStrip || {}).length !== 0;

  const onError = (e) => {
    if (lastImage !== e.currentTarget.src) {
      const srcImage = avatar
        ? avatar
        : loaderStaticImage({ src: productImageFallback });
      setLastImage(e.currentTarget.src);
      e.currentTarget.src = srcImage;
      e.currentTarget.srcset = generateSrcSet({ src: srcImage });
    }
  };

  return (
    <>
      <div
        className={classnames('product-image-wrapper')}
        tabIndex={tabIndex || -1}
      >
        <Image
          src={src || loaderStaticImage({ src: productImageFallback })}
          loader={loaderImage}
          className={classnames(
            'product-image',
            { 'product-image-with-strip': showStrip },
            className
          )}
          onError={onError}
          quality={quality || 50}
          priority={priority || false}
          data-testid={dataTestid || ''}
          alt={alt || ''}
          role={alt ? null : 'presentation'}
          width={width || 294}
          height={height || 294}
          layout={ width && height ? 'responsive' : layout}
          blurDataURL={blurDataURL || ''}
          placeholder={placeholder}
          onClick={() => isRedesign && imageModalRef.current?.openModal()}
        />
      </div>
      <ProductImageModal
        modalRef={imageModalRef}
        imageSrc={src || loaderStaticImage({ src: productImageFallback })}
        isMobile={isMobile}
      />
    </>
  );
};

export default React.memo(ProductImage);
