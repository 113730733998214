import Request from '@hotmart/request';
import config from '../../env.config';

export const Constants = {
  SERVICE_ONLINE_DAYS: 'service_online.days',
  SERVICE_ONLINE_HOURS: 'service_online.hours',
  SERVICE_ONLINE_OFFER: 'service_online.offer'
};

Request.register(config.envConfig.SERVICE_ONLINE, {
  get: {
    [Constants.SERVICE_ONLINE_DAYS]: '/v1/product/:ucode/schedules/days',
    [Constants.SERVICE_ONLINE_HOURS]: '/v1/product/:ucode/schedules/hours'
  },
  post: {
    [Constants.SERVICE_ONLINE_OFFER]: '/v1/product/:ucode/schedules/offer'
  }
});
