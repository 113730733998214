import Request from '@hotmart/request';
import config from '../../env.config';

export const Constants = {
  INFO_CAMPAIGN: 'info.campaign'
};

Request.register(config.envConfig.CAMPAIGN, {
  get: {
    [Constants.INFO_CAMPAIGN]: '/campaign/configs/:slug/config.json'
  }
});
