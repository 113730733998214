import React, { useState, useEffect, useRef, Fragment, useContext } from 'react';
import { withTranslation } from 'next-i18next';

import { ProfileContext } from '../core/Provider/ProfileProvider';
import LinkButton from '../LinkButton';
import { createCheckoutURL } from 'utils/checkout';
import ServiceOnline from '../../@online_service/service';
import classnames from 'classnames';
import SessionPackageAlert from 'components/SessionPackageAlert';
import { isSessionPackage } from '../../utils/sessions';
import { formatLocale } from '../../utils/date';
import { MainLoader } from '../Loader';
import dynamic from 'next/dynamic';
import { MONTHS } from '../../utils/localization'

import IconsImage from '../Images/Icons';
import { loaderStaticImage } from '../../utils/image';

const ChevronLeft = loaderStaticImage({src: 'static/images/icons/chevron-left.svg' });
const Check = loaderStaticImage({src: 'static/images/icons/check.svg' });

const DatePicker = dynamic(() => import('components/Calendar/DatePicker'));

const Calendar = (props) => {
  const [months, setMonths] = useState([]);
  const [loading, setLoading] = useState(false);
  const [ tooltip, setTooltip ] = useState(false)
  const [day, setDay] = useState('');
  const [selectedHour, setHour] = useState({});
  const [calendar, setCalendar] = useState(true);
  const [checkoutLink, setCheckoutLink] = useState('');
  const serviceOnlineButton = useRef(null);
  const {
    startDate = new Date(),
    highlightDays,
    locale,
    availableHours,
    t,
    modal,
    buyButtonOnClick
  } = props;
  const context = useContext(ProfileContext);
  const { checkout } = context;
  const sessionPackage = isSessionPackage(context);

  const checkServiceOnline = (ucode) => {
    return (
      ucode &&
      (context.checkout.onlineService || context.checkout.modalOnlineService)
    );
  };

  const selectDate = (date) => {
    const { profile } = context;
    const { product = {} } = profile;
    setLoading(true);

    if (checkServiceOnline(product.ucode)) {
      ServiceOnline.getHours({
        ucode: product.ucode,
        day: `${formatLocale(date, locale, null)}`
      })
        .then((days) => {
          setLoading(false);
          setCalendar(false);
          setDay(date);
          context.actions.setHours(days.availableHours);
        })
        .catch(_ => {
          setLoading(false);
        });
    }
  };

  const selectHour = (hour) => {
    const { product = {} } = context.profile;

    setLoading(true);

    if (checkServiceOnline(product.ucode)) {
      ServiceOnline.getOffer({ ucode: product.ucode, selectedAttendance: hour })
        .then((response) => {
          setHour(hour);
          mountCheckoutUrl(response);
          setLoading(false);
        })
        .catch((_) => {
          setLoading(false);
        });
    }
  };

  const checkHour = (hour) => {
    if (selectedHour) {
      return selectedHour.start === hour.start && selectedHour.end === hour.end;
    }
  };

  const backDate = () => {
    setDay(null);
    setHour(null);
    setCalendar(true);
    setCheckoutLink(null);
  };

  const mountCheckoutUrl = (offer) => {
    const { creditCardPaymentMethodList, src, sck, coupon } = context.checkout;
    const { product = {} } = context.profile;
    const { producerReferenceCode, cta } = product;

    if (offer && offer.offerKey) {
      setCheckoutLink(
        createCheckoutURL(
          cta,
          producerReferenceCode,
          offer.offerKey,
          src,
          sck,
          coupon
        )
      );
    }
  };

  useEffect(() => {
    if (locale) {
      const months = MONTHS[locale];
      setMonths(months);
    }
  }, [locale]);

  const buyButtonOnClickHandler = () => {
    if (buyButtonOnClick && typeof buyButtonOnClick === 'function') {
      buyButtonOnClick();
    }
  };

  return (
    <Fragment>
      <div
        className={`calendar-checkout ${
          context.checkout.onlineService || context.checkout.modalOnlineService
            ? 'openSchedule'
            : 'hideSchedule'
          }`}
        data-testid="calendar-checkout"
      >
        <MainLoader loading={loading} />
        <a
          className={`calendar-checkout-back ${!calendar ? 'show' : 'hide'}`}
          onClick={backDate}
        >
          <IconsImage src={ChevronLeft} alt={t('online_service.back')} width={11} height={11} className="icon-chevron"/>{' '}
          {t('online_service.back')}
        </a>
        {context.checkout.modalOnlineService && !calendar && (
          <hr className="calendar-modal__divisor back" />
        )}
        <div
          className={`calendar-checkout-dates ${calendar ? 'show' : 'hide'}`}
        >
          <DatePicker
            selected={startDate}
            startDate={startDate}
            selectDate={selectDate}
            highlightDays={highlightDays}
            months={months}
            locale={locale}
            tooltip={tooltip}
            setTooltip={setTooltip}
            isSessionPackage={sessionPackage}
          />
        </div>
        <div
          className={`calendar-checkout-hours__container ${
            !calendar ? 'show' : 'hide'
            }`}
        >
          <h4 className="calendar-checkout-hours__title">
            {t('online_service.pickHour')}
          </h4>
          <div
            className={`calendar-checkout-hours ${!calendar ? 'show' : 'hide'}`}
            data-testid="hours-checkout"
          >
            {availableHours &&
              availableHours.map((hour) => {
                return (
                  <div
                    key={`${hour.start}-${hour.end}`}
                    className={`calendar-checkout-hour ${
                      checkHour(hour) ? 'selected' : ''
                      }`}
                    onClick={() => selectHour(hour)}
                    data-testid="hour-available"
                  >
                    <strong>{formatLocale(hour.start, locale, 'H:mm')} </strong>{' '}
                    {t('online_service.hourSeparator')}
                    <strong>{formatLocale(hour.end, locale, 'H:mm')}</strong>
                  </div>
                );
              })}
          </div>
        </div>
        {day && !modal && (
          <div className="calendar-checkout-date__container" ref={serviceOnlineButton}>
            <div className="calendar-checkout-date__schedule">
              <span className="calendar-checkout-date__schedule--title">
                {t('online_service.schedule')}:
              </span>{' '}
              <span className="calendar-checkout-date__schedule--content">
                <span className="date">
                  {formatLocale(day, locale, 'dddd')}
                </span>{' '}
                <strong>
                  {formatLocale(day, locale, 'DD MMMM')}
                </strong>
              </span>
              {selectedHour && selectedHour.start && (
                <div className="calendar-checkout-hour__schedule">
                  <span className="calendar-checkout-hour__schedule--content">
                    {t('online_service.dateRangeSeparator')}
                    <strong>
                      {formatLocale(selectedHour.start, locale, 'H:mm')}{' '}
                      {t('online_service.hourSeparator')}{' '}
                      {formatLocale(selectedHour.end, locale, 'H:mm')}
                    </strong>
                  </span>
                  <span className="calendar-checkout-hour__schedule--check">
                    <IconsImage src={Check} alt={t('online_service.schedule')} width={11} height={11} className="icon-check"/>
                  </span>
                </div>
              )}
            </div>
            <div>
              <LinkButton
                text={t(`online_service.payment`)}
                icon={'shopping-cart'}
                theme={'service-checkout'}
                onClick={buyButtonOnClickHandler}
                href={checkoutLink}
                className={classnames('btn-buy schedule', {
                  enabled: checkoutLink,
                  disabled: !checkoutLink
                })}
                modal={modal}
              >
                {t('online_service.payment')}
              </LinkButton>
            </div>
          </div>
        )}

        {
          (sessionPackage && !modal) && (
            <SessionPackageAlert t={t} />
          )
        }
      </div>


      {modal && (
        <div
          className={classnames('calendar-checkout-date__container', 'modal', {
            show: day,
            hide: !day
          })}
        >
          <div className="calendar-checkout-date__schedule">
            <div className="calendar-checkout-hour__schedule">
              {day && (
                <Fragment>
                  <div className="calendar-checkout-date__schedule--title">
                    {t('online_service.schedule')}:
                  </div>
                  <div className="calendar-checkout-date__schedule--content">
                    <span className="date">
                      {formatLocale(day, locale, 'dddd')}
                    </span>{' '}
                    <strong>
                      {formatLocale(day, locale, 'DD MMMM')}
                    </strong>
                  </div>
                </Fragment>
              )}
              {selectedHour && selectedHour.start && (
                <div className="calendar-checkout-hour__schedule--content">
                  {t('online_service.dateRangeSeparator')}
                  <strong>
                    {formatLocale(selectedHour.start, locale, 'H:mm')}{' '}
                    {t('online_service.hourSeparator')}{' '}
                    {formatLocale(selectedHour.end, locale, 'H:mm')}
                  </strong>
                  <div className="calendar-checkout-hour__schedule--check">
                    <IconsImage src={Check} alt={t('online_service.schedule')} width={11} height={11} className="icon-check"/>
                  </div>
                </div>
              )}
            </div>
          </div>
          <LinkButton
            href={checkoutLink}
            text={t(`online_service.payment`)}
            icon={''}
            onClick={buyButtonOnClickHandler}
            theme={'service-checkout'}
            className={`btn-buy schedule ${checkoutLink ? 'enabled' : 'disabled'}`}
            modal={modal}
          >
            {t(`online_service.payment`)}
          </LinkButton>
        </div>
      )}
    </Fragment>
  );
};

export default withTranslation()(Calendar);