import { PropsWithChildren } from 'react';

import classnames from 'classnames';

import { DropdownPosition, DropdownSide } from './types';

import '@hotmart/cosmos/dist/dropdown';

type Props = {
  className?: string,
  position?: DropdownPosition,
  side?: DropdownSide,
  slot?: string,
  isDocked?: boolean
};

function Dropdown({
  children,
  className,
  position = DropdownPosition.BOTTOM,
  side = DropdownSide.LEFT,
  slot,
  isDocked
}: PropsWithChildren<Props> ) {
  return (
    <hot-dropdown
      class={classnames(className, position, { 'hot-dropdocked': isDocked })}
      menu-side={side}
      slot={slot}
    >
      {children}
    </hot-dropdown>
  );
}

export default Dropdown;
