import classNames from 'classnames';

export interface ContentLoaderProps {
  width?: number | string
  height?: number | string
  className?: string
}

function ContentLoader(props: ContentLoaderProps) {
  return (
    <div
      className={classNames('content-loader--container', props.className)}
      style={{ width: props.width, height: props.height }}
    >
      <div className="content-loader--loader">
        <div className="content-loader--shine" />
      </div>
    </div>
  );
}

export default ContentLoader;
