import React, { Fragment, useEffect, useState } from 'react';
import { withTranslation, Trans } from 'next-i18next';

import IconsImage from '../Images/Icons';
import { loaderStaticImage } from '../../utils/image';

const ExclamationTriangle = loaderStaticImage({src: 'static/images/icons/exclamation-triangle.svg' });

const PreviewAlert = ({ published, activeOnPlatform, ...props }) => {
  const [productUrl, setProductUrl] = useState(false);
  const { t } = props;

  useEffect(() => {
    if (typeof window !== 'undefined' && published && activeOnPlatform) {
      setProductUrl(window.location.pathname);
    }
  }, []);

  return (
    <Fragment>
      <div className="alert-preview">
        <IconsImage src={ExclamationTriangle} width={20} height={20} className="alert-preview__icon" />
        <span className="alert-preview__info">
          <span dangerouslySetInnerHTML={{__html: t('salesPage.preview_product')}}></span>
          { published && activeOnPlatform && productUrl && (
            <span>
              {' '}{t('salesPage.preview_product_link.text')}
              <a href={productUrl} className="alert-preview__info-link">
                {' '}{t('salesPage.preview_product_link.link')}
              </a>
            </span>
          )}
        </span>
      </div>
    </Fragment>
  )
};

export default withTranslation()(PreviewAlert);
